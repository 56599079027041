<template>
  <div id="login">
    <div class="login-header">
      <a href="https://complementservices.com" target="_blank"
        ><img src="../../assets/imgs/logo-cfs.png" class="login-header-logo"
      /></a>
      <div class="login-header-right">
        <div class="login-header-text">¿No tienes cuenta?</div>
        <md-button href="https://complementservices.com/contact/" class="login-header-button">Escríbenos</md-button>
      </div>
    </div>
    <b-row>
      <div id="login-form" data-aos="fade-down" class="mx-auto p-0">
        <form @submit.prevent="onSubmit">
          <md-card>
            <md-card-header>
              <div class="md-title">Bienvenid@</div>
            </md-card-header>
            <md-card-content>
              <md-field>
                <label>Correo Electrónico</label>
                <md-input
                  type="email"
                  name="email"
                  v-model="form.email"
                ></md-input>
              </md-field>
              <md-field>
                <label>Contraseña</label>
                <md-input
                  type="password"
                  name="password"
                  v-model="form.password"
                ></md-input>
              </md-field>
              <md-button type="submit" class="submit-button">Login</md-button>
              <div class="forgot-password" @click="showDialog = true">Olvidé mi contraseña</div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </b-row>
    <md-dialog v-if="showDialog" :md-active.sync="showDialog">
      <md-dialog-title>Restablescamos tu contraseña</md-dialog-title>
      <md-dialog-content>
        <md-field>
          <label>Ingresa tu correo</label>
          <md-input v-model="resetEmail" />
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="resetPassword()"
          >Aceptar</md-button
        >
        <md-button class="md-accent" @click="showDialog = false"
          >Cerrar</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Auth from "@/services/Auth";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      resetEmail: "",
      showDialog: false,
      isLoading: false,
      error: false,
    };
  },
  methods: {
    onSubmit(){
      this.error = false;
      this.isLoading = true;
      let nextRoute = "home";
      if(this.form.email && this.form.password){
      Auth.login(this.form)
        .then((user) => {
          user.data.isAuthenticated = true;
          this.$store.commit("setData", user.data);
          switch(user.data.role){
          case 'SUPERCOM':
            nextRoute = {name: 'home'};
            break;
          case 'ADMIN':
            nextRoute = {name: 'home'};
            break;
          case 'COM':
            nextRoute = {name: 'home'};
            break;
          case 'CLIENT':
            nextRoute = {name: 'service'};
            break;
        }
        })
        .catch((err) => {
          this.error = true;
          if(err.response.status == 404 || err.response.status == 409){
            this.$toast.error("Revisa tus credenciales")
          }else{
          this.$toast.error("Algo pasó, intentalo de nuevo")
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.$router.push(nextRoute );
        });
    }else{
        this.$toast.error("Diligencia los campos")
        this.isLoading = false;
    }
    },
    resetPassword(){
      this.showDialog = false
      this.isLoading = true

      Auth.resetPassword({email: this.resetEmail})
        .then(() => {
          this.$toast.success("Te enviaremos un correo con los pasos a seguir")
        })
        .catch((err) => {
          this.error = true;
          if(err.response.status == 404){
            this.$toast.error(err.response.data.message)
          }else{
          this.$toast.error("Algo pasó, intentalo de nuevo")
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.resetEmail = ""  
        });
    } 
  },
  beforeMount() {
    if (this.$store.state.isAuthenticated) {
      let nextRoute = { name: "home" }
      switch(this.$store.state.userRole){
          case 'SUPERCOM':
            nextRoute = {name: 'home'};
            break;
          case 'COM':
            nextRoute = {name: 'home'};
            break;
          case 'CLIENT':
            nextRoute = {name: 'service'};
            break;
        }
      this.$router.push(nextRoute);
    }
  },
};
</script>

<style></style>
