<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="8" lg="8" class="mx-auto">
          <h1>Proyectos por Cliente</h1>
          <div class="text-center p-4" v-if="isLoading">
              <b-spinner></b-spinner>
          </div>
          <div v-else>
          <md-card class="text-left" id="user-box" v-if="clients.length > 0 ">
            <md-card-area md-inset v-custom-click-outside="hide">
              <a @click="displayClients()">
                <md-card-header id="detailed-report-user">
                  <md-avatar v-if="selectedClient != null">
                    <h2>{{ selectedClientObj.name[0].toUpperCase() }}</h2>
                  </md-avatar>
                  <div class="md-title" v-if="selectedClient != null">
                    {{ selectedClientObj.name }}
                  </div>
                  <div class="md-subhead" v-else>Selecciona un cliente</div>
                  <div class="md-subhead" v-if="selectedClient != null">
                    {{ selectedClientObj.code }}
                  </div>
                </md-card-header>
              </a>
              <md-card-header v-if="showClients" class="second-header">
                <div id="list-of-users">
                  <div
                    v-for="item in clients"
                    :key="item.id"
                    class="user-item"
                    @click="hideClients(item.id)"
                    :class="{ active: item.id == selectedClient }"
                  >
                    <md-avatar>
                      <h2>{{ item.name[0].toUpperCase() }}</h2>
                    </md-avatar>
                    <div class="md-title">{{ item.name }}</div>
                    <div class="md-subhead">{{ item.code }}</div>
                  </div>
                </div>
              </md-card-header>
            </md-card-area>
            <md-card-actions class="justify-content-center">
              <md-button class="md-primary" @click="showClientDialog = true"
                >Crear Nuevo Cliente</md-button
              >
            </md-card-actions>
          </md-card>         
          <div v-if="clients.length == 0" class="pt-4">
            <md-empty-state
              md-icon="devices_other"
              md-label="Crea tu primer cliente"
              md-description="Podras asociar proyectos y tareas para cada cliente"
            >
              <md-button
                class="md-primary md-raised"
                @click="showClientDialog = true"
                >Crear</md-button
              >
            </md-empty-state>
          </div>
          </div>
          <md-list v-if="clientProjects.length > 0" id="project-list">
            <div class="text-center" v-if="isLoadingProject">
              <b-spinner></b-spinner>
            </div>
            <md-list-item
              class="md-inset add-project"
              @click="addProject()"
              v-if="!showNewProject"
            >
              Agregar Proyecto
              <font-awesome-icon :icon="['fas', 'plus']" class="md-icon"
            /></md-list-item>
            <ProjectComponent
              v-model="projectItem"
              :options="status"
              v-else
              :key="'new-project-' + selectedClient"
              :clientId="selectedClient"
              @newProjectEvent="createNewProject($event)"
            />
            <md-list-item
              md-expand
              v-for="item in clientProjects"
              :key="'pj-id-' + item.id"
            >
              <!-- <font-awesome-icon :icon="['fas', 'project-diagram']" class="md-icon" /> -->
              <span class="md-list-item-text">{{ item.name }}</span>
              <span class="md-list-item-text" :class="item.status">{{
                getStatus(item.status)
              }}</span>

              <md-list slot="md-expand">
                <div class="text-center" v-if="isLoadingTask">
                  <b-spinner></b-spinner>
                </div>
                <md-list-item
                  class="md-inset"
                  v-for="task in getProjectTasks(item.id)"
                  :key="'task-id-' + task.id"
                  >{{ task.name }}</md-list-item
                >
                <md-list-item
                  class="md-inset add-task"
                  @click="addTask(item.id)"
                  v-if="showTaskIdx != item.id"
                >
                  Agregar Tarea
                  <font-awesome-icon :icon="['fas', 'plus']" class="md-icon"
                /></md-list-item>
                <TaskComponent
                  v-model="taskItem"
                  :options="status"
                  v-if="showTaskIdx == item.id"
                  :key="'new-task-' + item.id"
                  :projectId="item.id"
                  @newTaskEvent="createNewTask($event)"
                />
              </md-list>
            </md-list-item>
          </md-list>
          <div v-if="clientProjects.length == 0 && selectedClient">
            <md-empty-state
              md-icon="devices_other"
              md-label="Crea el primer proyecto para este cliente"
              md-description="Podras agregar las actividades asociadas a los proyectos"
            >
              <md-button class="md-primary md-raised" @click="showDialog = true"
                >Crear</md-button
              >
            </md-empty-state>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <md-dialog v-if="showDialog" :md-active.sync="showDialog">
      <md-dialog-title> Diligencia los datos </md-dialog-title>
      <md-dialog-content>
        <ProjectComponent
          v-model="projectItem"
          :options="status"
          :key="'new-project-' + selectedClient"
          :clientId="selectedClient"
          @newProjectEvent="createNewProject($event)"
        />
      </md-dialog-content>
    </md-dialog>
    <md-dialog v-if="showClientDialog" :md-active.sync="showClientDialog">
      <md-dialog-title> Diligencia los datos </md-dialog-title>
      <md-dialog-content>
        <div class="text-center" v-if="isLoadingClient  ">
          <b-spinner></b-spinner>
        </div>
        <ClientComponent
          v-model="clientItem"
          key="new-client"
          :userId="$store.state.userId"
          @newClientEvent="createNewClient($event)"
        />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
// import SelectFor from "@/components/utils/SelectFor";

// import parse from "date-fns/parse";
import TaskComponent from "@/components/time-report/task";
import ProjectComponent from "@/components/time-report/project";
import ClientComponent from "@/components/time-report/client";

import format from "date-fns/format";
import Client from "@/services/time-report/Client";
import Project from "@/services/time-report/Project";
import Task from "@/services/time-report/Task";

export default {
  data() {
    return {
      selectedDate: null,
      disabledDates: (date) => {
        const day = date.getDay();
        return (
          day === 0 ||
          day === 2 ||
          day === 3 ||
          day === 4 ||
          day === 5 ||
          day === 6
        );
      },
      taskItem: {
        projectId: "",
        status: "",
        name: "",
      },
      projectItem: {
        clientId: "",
        status: "",
        name: "",
      },
      clientItem: {
        userId: "",
        code: "",
        name: "",
      },
      selectedClient: null,
      create: false,
      showDialog: false,
      showClientDialog: false,
      showNewProject: null,
      showTaskIdx: null,
      isLoading: false,
      isLoadingTask: false,
      isLoadingProject: false,
      isLoadingClient: false,

      inputLabel: "Company",
      showClients: false,
      status: [
        {
          value: "sin_comenzar",
          name: "Sin Comenzar",
        },
        {
          value: "en_progreso",
          name: "En Progreso",
        },
        {
          value: "devuelta",
          name: "Devuelta",
        },
        {
          value: "terminada",
          name: "Terminada",
        },
      ],
      users: [],
      clients: [],
      projects: [],
      task: [],
    };
  },
  components: {
    // SelectFor
    // CreateButton,
    TaskComponent,
    ProjectComponent,
    ClientComponent,
  },
  computed: {
    dateFormat() {
      return this.$material.locale.dateFormat || "dd/MM/yyyy";
    },
    daysLabels() {
      return {
        mon: new Date(this.selectedDate).getDate(),
        tue: new Date(
          new Date(this.selectedDate).getTime() + 1 * 86400000
        ).getDate(),
        wed: new Date(
          new Date(this.selectedDate).getTime() + 2 * 86400000
        ).getDate(),
        thu: new Date(
          new Date(this.selectedDate).getTime() + 3 * 86400000
        ).getDate(),
        fri: new Date(
          new Date(this.selectedDate).getTime() + 4 * 86400000
        ).getDate(),
        sat: new Date(
          new Date(this.selectedDate).getTime() + 5 * 86400000
        ).getDate(),
      };
    },
    selectedClientObj() {
      return {
        name:
          this.clients[
            this.clients.findIndex((el) => el.id == this.selectedClient)
          ].name || null,
        code:
          this.clients[
            this.clients.findIndex((el) => el.id == this.selectedClient)
          ].code || null,
      };
    },
    clientProjects() {
      return this.projects.filter((el) => el.clientId == this.selectedClient); //.map( el => {el.addingTask = false; return el})
    },
  },
  methods: {
    // toDate(currDate) {
    //   return parse(currDate, this.dateFormat, new Date());
    // },
    getStatus(status) {
      return this.status[this.status.findIndex((el) => el.value == status)]
        .name;
    },
    getProjectTasks(projectId) {
      return this.tasks.filter((el) => el.projectId == projectId);
    },
    toString(currDate) {
      return format(new Date(currDate), this.dateFormat);
    },
    addProject() {
      this.showNewProject = true;
    },
    addTask(projectId) {
      this.showTaskIdx = projectId;
    },
    createNewTask(event) {
      this.isLoadingTask = true;
      this.taskItem = event;
      this.showTaskIdx = null;
      Task.createOne(this.taskItem)
        .then((result) => {
          console.log(result.data);
          this.tasks.push(result.data.data);
          this.$toast.success("Creado Correctamente");
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Algo pasó");
        })
        .finally(() => {
          this.taskItem = {
            projectId: "",
            status: "",
            name: "",
          };
          this.isLoadingTask = false;
        });
    },
    createNewProject(event) {
      this.isLoadingProject = true;
      this.projectItem = event;
      this.showNewProject = false;
      if (this.showDialog) {
        this.showDialog = false;
      }
      Project.createOne(this.projectItem)
        .then((result) => {
          console.log(result.data);
          this.projects.push(result.data.data);
          this.$toast.success("Creado Correctamente");
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Algo pasó");
        })
        .finally(() => {
          this.projectItem = {
            clientId: "",
            status: "",
            name: "",
          };
          this.isLoadingProject = false;
        });
    },
    createNewClient(event) {
      this.isLoadingClient = true;
      this.clientItem = event;
      Client.createOne(this.clientItem)
        .then((result) => {
          console.log(result.data);
          this.clients.push(result.data.data);
          this.$toast.success("Creado Correctamente");
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Algo pasó");
        })
        .finally(() => {
          this.isLoadingClient = false;
          this.showClientDialog = false;
          this.clientItem = {
            userId: "",
            code: "",
            name: "",
          };
        });
    },
    displayClients() {
      this.showClients = true;
    },
    hideClients(id) {
      this.selectedClient = id;
      this.showClients = false;
    },
    hide: function() {
      this.showClients = false;
    },
  },
  mounted() {
    this.isLoading = true;
    Client.getAll()
      .then((result) => {
        this.clients = result.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    Project.getAll()
      .then((result) => {
        this.projects = result.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    Task.getAll()
      .then((result) => {
        this.tasks = result.data.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style src="../../main.css"></style>
