<template>
<div v-if="$store.state.isAuthenticated">
  <div class="py-0" id="sidebar" v-if="$route.name !== 'home'">
    <!-- <b-container-fluid> -->
    <ul>
      <li
        v-for="(item, idx) in pages"
        :key="idx"
        :class="{ 'active-page': $route.name == item.route }"
      >
        <a :href="item.ref">
          <font-awesome-icon :icon="['fas', item.icon]" />
          <span>{{ item.name }}</span>
        </a>
      </li>
    </ul>
    <!-- </b-container-fluid> -->
  </div>
  <div v-else>
    <b-container >
      <b-row id="home-buttons">
        <b-col md="6" class="p-2" v-for="(item,idx) in pages" :key="idx"  @click="goToRoute(item.route)">
          <md-card md-with-hover >
            <md-ripple>
              <md-card-header>
                <div class="md-title"><font-awesome-icon :icon="['fas', item.icon]" />
                <span>{{ item.name }}</span></div>
                <!-- <div class="md-subhead">It also have a ripple</div> -->
              </md-card-header>
              <md-card-content>
          {{item.description}}</md-card-content>
            </md-ripple>
          </md-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      menu: "",
      home: "/#home",
      scroll: false,
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.scrollFunction);
  },
  methods: {
    scrollFunction(e) {
      this.scroll = window.pageYOffset > 300;
      e.preventDefault();
    },
    logout: function() {
      const obj = {
        token: "",
        username: "",
        role: "",
        expiresIn: "",
        isAuthenticated: false,
      };
      this.$store.commit("setData", obj);
      this.$router.push("/");
    },
    goToRoute(page){
      this.$router.push({name: page })
    }
  },
  computed: {
    // role() {
    //   // console.log(this.$store.state.userRole)
    //   return this.$store.state.userRole;
    // },
    // isAuthenticated() {
    //   // console.log(this.$store.state.isAuthenticated)
    //   return this.$store.state.isAuthenticated;
    // },
    // language() {
    //   // console.log(this.$store.state._language)
    //   return this.$store.state._language;
    // },
    labels() {
      if(this.$route.name == "home"){
        return {
          pages: [
            {
              name: "Ventas",
              ref: "/sales/funnel",
              route: "funnel",
              roles: ["SUPERCOM", "COM"],
              icon: "funnel-dollar",
              header: "Reporte de ventas",
              description: "Herramienta para documentar las ventas segun su estado, probabilidad y servicio. Se pueden ver en la tabla y en mbudo de ventas",
            },
            {
              name: "Reporte de Tiempo",
              ref: "/time-report/general",
              route: "general",
              roles: ["SUPERCOM", "COM"],
              icon: "calendar-alt",
              header: "Reporte de Tiempo",
              description: "Herramienta para documentar el tiempo en cada una de las tareas y actividades realizadas por proyecto",
            },
            {
              name: "Manejo de Proyectos",
              ref: "/customer/service",
              route: "service",
              roles: ["SUPERCOM", "COM"],
              icon: "project-diagram",
              header: "Manejo de Proyectos",
              description: "Herramienta para visualizar los proyectos y actividades relacionadas con el mismo",
            },
            {
              name: "Usuarios y Empresas",
              ref: "/users",
              route: "users",
              roles: ["SUPERCOM"],
              icon: "users",
              header: "usuarios y Empresas",
              description: "Manejo de usuarios y empresas que se conectan a la aplicación",
            },

          ],
        }
      }else if(this.$route.name == "activity" || this.$route.name == "service" || this.$route.name == "document" || this.$route.name == "deliver"){
         return {
          pages: [
            {
              name: "Servicios",
              ref: "/customer/service",
              route: "service",
              roles: ["SUPERCOM", "COM", "CLIENT"],
              icon: "concierge-bell",
            },
            {
              name: "Actividades",
              ref: "/customer/activity",
              route: "activity",
              roles: ["SUPERCOM", "COM", "CLIENT"],
              icon: "tasks",
            },
            {
              name: "Documentos",
              ref: "/customer/document",
              route: "document",
              roles: ["SUPERCOM", "COM", "CLIENT"],
              icon: "folder-open",
            },
            {
              name: "Entregables",
              ref: "/customer/deliver",
              route: "deliver",
              roles: ["SUPERCOM", "COM", "CLIENT"],
              icon: "file-pdf",
            },
          ],
        }
      }else if(this.$route.name == 'detailed' || this.$route.name == 'detailed-report' || this.$route.name == 'general' || this.$route.name == 'week' || this.$route.name == 'summary'){
         return {
          pages: [
            {
              name: "Resumen",
              ref: '/time-report/summary',
              route: "summary",
              roles: ["SUPERCOM"],
              icon: "sitemap",
            },
            {
              name: "General",
              ref: '/time-report/general',
              route: "general",
              roles: ["SUPERCOM","COM"],
              icon: "calendar-alt",
            },
            {
              name: "Detallado",
              ref: '/time-report/detailed',
              route: "detailed",
              roles: ["SUPERCOM","COM"],
              icon: "id-card",
            },
            {
              name: "Semanal",
              ref: '/time-report/week',
              route: "week",
              roles: ["SUPERCOM","COM"],
              icon: "calendar-week",
            },
          ],
        }
      }else if(this.$route.name == 'users' || this.$route.name == 'companies' || this.$route.name == 'users-create' || this.$route.name == 'companies-create'){
        return {
          pages: [
            {
              name: "Usuarios",
              ref: "/users",
              route: "users",
              roles: ["SUPERCOM"],
              icon: "users",
            },
            {
              name: "Compañias",
              ref: "/companies",
              route: "companies",
              roles: ["SUPERCOM"],
              icon: "building",
            },
          ]
        }
      }else{
        return {
          pages: [
            {
              name: "Home",
              ref: "/",
              route: "home",
              roles: ["SUPERCOM","COM"],
              icon: "home",
            },
          ],
        }
      }
     },
    pages() {
      return this.labels.pages.filter((page) => {
        return page.roles.find((item) => {
          return item === this.$store.state.userRole;
          // return item === "COM"
        });
      });
    },
  },
};
</script>

<style>
/* d-none d-md-flex */
#sidebar {
  position: fixed;
  top: 135px;
  left: 0;
  height: 100%;
  width: 60px;
  /* box-shadow: 2px 0px 20px 0 rgba(31, 38, 135, 0.37); */
  /* backdrop-filter: blur(4px); */
  /* -webkit-backdrop-filter: blur(4px); */
  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
  /* display: block; */
  transition: 0.2s width ease;
  z-index: 10;
  /* padding: 20px; */
  /* overflow-x: hidden; */
  font-weight: 500;
  /* letter-spacing: 1; */
}
#sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#sidebar ul li {
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 25px;
  transition: all 0.3s ease;
  margin: 5px 0;
  padding: 0 6px;
    overflow: hidden;
}
#sidebar:hover ul li {
  padding-left: 12px;
}
#sidebar ul li:nth-child(1) {
  background: var(--four);
}
#sidebar ul li:nth-child(2) {
  background: #e91e63;
}
#sidebar ul li:nth-child(3) {
  background: #3f51b5;
}
#sidebar ul li:nth-child(4) {
  background: #009688;
}

/* #sidebar ul li.active-page svg{

} */
#sidebar ul li:hover {
  padding-left: 16px;
  /* background: rgba(255, 255, 255, 0.5); */
}
#sidebar ul li a {
  display: flex;
  align-items: center;
  height: 40px;
  color: var(--five) !important;
  text-decoration: none;
  font-size: 1.1rem;
}

#sidebar ul li a span {
  display: none;
  margin-left: 1rem;
}

#sidebar ul li svg {
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 6px;
  font-size: 2rem;
  color: var(--five);
}
#sidebar:hover {
  width: 200px;
}

#sidebar:hover span {
  display: block;
}


#sidebar ul li:nth-child(1).active-page {
  border: 1px solid var(--four);
  background: #fff;
}
#sidebar ul li:nth-child(2).active-page {
  border: 1px solid #e91e63;
  background: #fff;
}
#sidebar ul li:nth-child(3).active-page {
  border: 1px solid #3f51b5;
  background: #fff;
}
#sidebar ul li:nth-child(4).active-page {
  border: 1px solid #009688;
  background: #fff;
}

#sidebar ul li:nth-child(1).active-page a, #sidebar ul li:nth-child(1).active-page a svg{
  color: var(--four) !important;
}
#sidebar ul li:nth-child(2).active-page a, #sidebar ul li:nth-child(2).active-page a svg{
  color: #e91e63 !important;
}
#sidebar ul li:nth-child(3).active-page a, #sidebar ul li:nth-child(3).active-page a svg{
  color: #3f51b5 !important;
}
#sidebar ul li:nth-child(4).active-page a, #sidebar ul li:nth-child(4).active-page a svg{
  color: #009688 !important;
}

#sidebar ul li.active-page{
  /* transform: scale(1.05); */
  box-shadow: 0px 0px 3px 1px rgba(163,163,163,0.5);
}

#home-buttons{
  max-width: 1140px;
  position: absolute;
  top: 95px;
  margin: 0 auto;
  padding: 35px;
  width: 100%;
}
#home-buttons .col-md-6{
  max-width: 50%;
}
#home-buttons .col-md-6 svg{
margin-right: 16px;
}
#home-buttons .col-md-6:nth-child(1) .md-card-header{
  color: var(--four) !important;
}
#home-buttons .col-md-6:nth-child(2) .md-card-header{
  color: #e91e63 !important;
}
#home-buttons .col-md-6:nth-child(3) .md-card-header{
  color: #3f51b5 !important;
}
#home-buttons .col-md-6:nth-child(4) .md-card-header{
  color: #009688 !important;
}
/* Small screens */
@media (max-width: 575.98px) {
  #sidebar {
    top: auto;
    bottom: 0;
    min-width: 100%;
    width: 100%;
    height: 3rem;
    overflow: hidden;
  }
  #sidebar ul {
    flex-direction: row;
    justify-content: center;
  }
  #sidebar ul li:first-child {
    display: none;
  }
  #sidebar:hover ul li {
    padding-left: 0px;
  }
  #sidebar ul li:hover {
    padding-left: 0px;
    background: var(--seven);
  }
  #sidebar:hover {
    min-width: 100%;
  }

  #sidebar:hover span {
    display: none;
  }
  #sidebar ul li a {
    justify-content: center;
  }
}
</style>
