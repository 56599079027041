<template>
  <div>
    <CreateButton :create="create" @toggleCreates="create = $event"  v-if="$store.state.canEdit"/>
    <b-container>
      <b-row v-if="!create">
        <b-col md="10" lg="12" class="mx-auto">
          <!-- <SelectFor v-model="form.companyId" :input-label="inputLabel" :options="options"/> -->
          <h1>Actividades</h1>
          <div v-if="!isLoading">
          <md-table v-model="items" md-sort="name" md-sort-order="asc" v-if="items.length > 0" class="customer">
            <md-table-toolbar>
              <h1 class="md-title text-left">Complement Services</h1>
            </md-table-toolbar>
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              @click="showThisInfo(item.id)"
            >
              <md-table-cell md-label="Compañia" md-sort-by="companyId"  v-if="$store.state.canEdit">{{
                companies[companies.findIndex((e) => e.id == item.companyId)]
                  .name
              }}</md-table-cell>
              <md-table-cell md-label="Servicio" md-sort-by="name">{{
                services[services.findIndex((e) => e.id == item.serviceId)].name
              }}</md-table-cell>
              <md-table-cell md-label="Actividad" md-sort-by="activityName">{{
                item.name
              }}</md-table-cell>
              <md-table-cell
                md-label="Estado"
                md-sort-by="status"
                class="td-status"
                :class="item.status"
                >{{
                  status[status.findIndex((el) => el.value == item.status)].name
                }}</md-table-cell
              >
              <md-table-cell
                md-label="Fecha de inicio"
                md-sort-by="startDate"
                >{{ toString(item.startDate) }}</md-table-cell
              >
              <md-table-cell
                md-label="Fecha Estimada de Entrega"
                md-sort-by="estimatedEndDate"
                >{{ toString(item.estimatedEndDate) }}</md-table-cell
              >
              <md-table-cell md-label="Comentarios" md-sort-by="submission">{{
                item.comments
              }}</md-table-cell>
            </md-table-row>
          </md-table>
          <div v-else>
            <md-empty-state
              md-icon="devices_other"
              md-label="Crea tu primera actividad"
              md-description="Agrega los datos correspondientes" v-if="$store.state.canEdit">
              <md-button class="md-primary md-raised" @click="create = true">Crear</md-button>
            </md-empty-state>
            <md-empty-state
              md-icon="feedback"
              md-label="Aun no tienes actividades asociadas"
              md-description="Muy pronto podras visualizarlas" v-else>
            </md-empty-state>
          </div>
          </div>
          <div class="text-center" v-else><b-spinner></b-spinner></div>
        </b-col>
      </b-row>
      <!-- <h2>Value is: {{form.companyId}}</h2> -->
      <b-row v-else>
        <b-col md="8" sm="10" lg="6" class="mx-auto">
          <h1>Crear Actividad</h1>
          <md-card>
            <md-card-content>
              <form @submit.prevent="submitForm" >
                <b-form-group>
                  <md-field>
                    <md-select
                      v-model="activity.companyId"
                      placeholder="Seleccione una compañia"
                      @input="clearService()"
                    >
                      <md-option
                        v-for="(item, idx) in companies"
                        :key="'company-id-act-' + idx"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                  <md-field v-if="activity.companyId">
                    <md-select
                      v-model="activity.serviceId"
                      placeholder="Seleccione un servicio"
                    >
                      <md-option
                        v-for="(item, idx) in services.filter(
                          (e) => e.companyId == activity.companyId
                        )"
                        :key="'service-id-act-' + idx"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                  <md-field>
                    <label>Nombre de la actividad</label>
                    <md-input
                      v-model="activity.name"
                      md-counter="50"
                    ></md-input>
                  </md-field>
                  <md-field>
                    <md-select v-model="activity.status" placeholder="Estado">
                      <md-option
                        v-for="(item, idx) in status"
                        :key="'status-id-act-' + idx"
                        :value="item.value"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                  <md-datepicker v-model="activity.startDate">
                    <label>Fecha de inicio</label>
                  </md-datepicker>
                  <md-datepicker v-model="activity.estimatedEndDate">
                    <label>Fecha estimada de entrega</label>
                  </md-datepicker>
                  <md-field>
                    <label>Comentarios</label>
                    <md-textarea
                      v-model="activity.comments"
                      md-autogrow
                    ></md-textarea>
                  </md-field>

                  <md-button type="submit" class="create-button">Crear</md-button>
                </b-form-group>
              </form>
            </md-card-content>
          </md-card>
        </b-col>
      </b-row>
    </b-container>
    <md-dialog v-if="showDialog" :md-active.sync="showDialog">
      <md-dialog-title>{{ dialogItem.name }}</md-dialog-title>
      <md-dialog-content>
        <md-field>
          <md-select v-model="dialogItem.status" placeholder="Estado">
            <md-option
              v-for="(item, idx) in status"
              :key="'status-id-act-' + idx"
              :value="item.value"
              >{{ item.name }}</md-option
            >
          </md-select>
        </md-field>
        <md-field>
          <label>Comentarios</label>
          <md-textarea v-model="dialogItem.comments" md-autogrow></md-textarea>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="updateActivity(dialogItem.id)"
          >Actualizar</md-button
        >
        <md-button class="md-accent" @click="showDialog = false"
          >Cerrar</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
// import SelectFor from "@/components/utils/SelectFor";
// import parse from "date-fns/parse";
import format from "date-fns/format";
import CreateButton from "@/components/utils/CreateButton";
import Activity from "@/services/customer/Activity";
import Service from "@/services/customer/Service";
import Company from "@/services/Company";


export default {
  data() {
    return {
      activity: {
        name: "",
        companyId: "",
        serviceId: "",
        status: "",
        startDate: null,
        estimatedEndDate: null,
        comments: "",
      },
      create: false,
      showDialog: false,
      dialogItem: {},
      string: null,
      isLoading: false,
      inputLabel: "Company",
      status: [
        {
          value: "sin_comenzar",
          name: "Sin Comenzar",
        },
        {
          value: "en_progreso",
          name: "En Progreso",
        },
        {
          value: "devuelta",
          name: "Devuelta",
        },
        {
          value: "terminada",
          name: "Terminada",
        },
      ],
      form: {
        companyId: "",
      },
      items: [
        {
          id: "1",
          serviceId: "asd-01",
          name: "Activity",
          status: "sin_comenzar",
          startDate: "Sun May 01 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          estimatedEndDate: "Sun May 02 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          comments: "This is a short comment",
          companyId: "01",
        },
        {
          id: "2",
          serviceId: "asd-02",
          name: "Activity",
          status: "sin_comenzar",
          startDate: "Sun May 01 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          estimatedEndDate: "Sun May 02 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          comments: "This is a short comment",
          companyId: "02",
        },
        {
          id: "3",
          serviceId: "asd-03",
          name: "Activity",
          status: "sin_comenzar",
          startDate: "Sun May 01 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          estimatedEndDate: "Sun May 02 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          comments: "This is a short comment",
          companyId: "03",
        },
        {
          id: "4",
          serviceId: "asd-04",
          name: "Activity",
          status: "sin_comenzar",
          startDate: "Sun May 01 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          estimatedEndDate: "Sun May 02 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          comments: "This is a short comment",
          companyId: "04",
        },
        {
          id: "5",
          serviceId: "asd-05",
          name: "Activity",
          status: "sin_comenzar",
          startDate: "Sun May 01 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          estimatedEndDate: "Sun May 02 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          comments: "This is a short comment",
          companyId: "05",
        },
        {
          id: "6",
          serviceId: "asd-06",
          name: "Activity",
          status: "sin_comenzar",
          startDate: "Sun May 01 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          estimatedEndDate: "Sun May 02 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          comments: "This is a short comment",
          companyId: "06",
        },
        {
          id: "7",
          serviceId: "asd-07",
          name: "Activity",
          status: "sin_comenzar",
          startDate: "Sun May 01 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          estimatedEndDate: "Sun May 22 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          comments: "This is a short comment",
          companyId: "07",
        },
        {
          id: "8",
          serviceId: "asd-08",
          name: "Activity",
          status: "sin_comenzar",
          startDate: "Sun May 01 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          estimatedEndDate: "Sun May 12 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          comments: "This is a short comment",
          companyId: "08",
        },
        {
          id: "9",
          serviceId: "asd-13",
          name: "Activity",
          status: "en_progreso",
          startDate: "Sun May 01 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          estimatedEndDate: "Sun May 08 2021 00:00:00 GMT-0400 (hora estándar de Chile)",
          comments:
            "Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.",
          companyId: "09",
        },
      ],
      companies: [],
      services: [],
    };
  },
  components: {
    // SelectFor
    CreateButton,
  },
  computed: {
    dateFormat() {
      return this.$material.locale.dateFormat || "dd/MM/yyyy";
    },
  },
  methods: {
    // toDate(currDate) {
    //   return parse(currDate, this.dateFormat, new Date());
    // },
    toString(currDate) {
      return format(new Date(currDate),this.dateFormat);
    },
    // toggleCreate() {
    //   this.create = !this.create;
    // },
    submitForm() {
      this.isLoading = false
      if(this.activity.companyId && this.activity.name  && this.activity.serviceId && this.activity.startDate && this.activity.estimatedEndDate){
      Activity.createOne(this.activity)
        .then((result) => {
            this.$toast.success("Creado Correctamente")
            this.items.push(result.data.data);
        }).catch((err) => {
            this.$toast.error("Algo pasó")
            console.log(err)
        }).finally(() => {
          this.create = false
          this.isLoading = false
          this.resetForm();
        })
        }else{
      this.$toast.error("Diligencia los campos")
      this.isLoading = false;
    }
    },
    async updateActivity(id){
      try {
        console.log(this.dialogItem)
        const keysToUpdate = {
            status: this.dialogItem.status,
            comments: this.dialogItem.comments           
        }
        await Activity.updateOne(id, keysToUpdate)
      }catch(error){
        this.$toast.error("Algo pasó")
        console.log(error)
      }
      this.$toast.success("Actualizado Correctamente")
      this.showDialog = false
    },
    resetForm() {
      this.activity = {
        name: "",
        companyId: "",
        serviceId: "",
        status: "",
        startDate: null,
        estimatedEndDate: null,
        comments: "",
      };
    },
    showThisInfo(id) {
      if(this.$store.state.canEdit){
      this.showDialog = true;
      this.dialogItem = this.items.filter((el) => el.id == id)[0];
      }
    },
    clearService() {
      this.activity.serviceId = "";
    },
  },
  async mounted(){
    this.isLoading = true
   try {
      this.items = (await Activity.getAll()).data.data
      this.companies = (await Company.getAll()).data.companies
      this.services = (await Service.getAll()).data.data
      if(!this.$store.state.canEdit){
        this.services = this.services.filter(el => el.companyId == this.$store.state.companyId)
        this.items = this.items.filter(el => el.companyId == this.$store.state.companyId)
      }
   } catch (error) {
     console.log(error)
   }
   this.isLoading = false
  }
};
</script>

<style src="../../main.css"></style>
