<template>
  <button class="fab-toggle" @click="toggleCreate()">
    <div class="fab-toggle-content" v-if="create">
      <font-awesome-icon :icon="['fas', 'list-ul']" /><span>Listado</span>
    </div>
    <div class="fab-toggle-content" v-else>
      <font-awesome-icon :icon="['fas', 'plus']" /><span>Crear</span>
    </div>
  </button>
</template>

<script>
export default {
    name: 'CreateButton',
  props: {
    create: Boolean,
  },
  methods: {
    toggleCreate() {
      this.$emit('toggleCreates',!this.create);
    },
  },
};
</script>

<style></style>
