import Api from '@/services/Api'

export default {
    createOne(form){
        return Api().post('delivers',form)
    },
    getAll(){
        return Api().get('delivers')
    },
    getAllByCompany(id){
        return Api().get('delivers/company/'+id)
    },
    getAllByService(id){
        return Api().get('delivers/service/'+id)
    },
    updateOne(id, form){
        return Api().put('delivers/update/'+id,form)
    },
    downloadFile(id){
        return Api().get('delivers/download/'+id, {
          responseType: 'blob'
      })
    },
}