import Api from '@/services/Api'

export default {
    createOne(form){
        return Api().post('documents',form)
    },
    getAll(){
        return Api().get('documents')
    },
    getAllByCompany(id){
        return Api().get('documents/company/'+id)
    },
    getAllByService(id){
        return Api().get('documents/service/'+id)
    },
    updateOne(id, form){
        return Api().put('documents/update/'+id,form)
    },
    downloadFile(id){
        return Api().get('documents/download/'+id, {
          responseType: 'blob'
      })
    },
}