import Api from "@/services/Api";

export default {
  getAll() {
    return Api().get("tasks");
  },
  getOneById(id) {
    return Api().get("tasks/" + id);
  },
  deleteById(id) {
    return Api().put("tasks/delete/" + id);
  },
  updateOneById(id,task) {
    return Api().put("tasks/update/"+id,task);
  },
  createOne(form) {
    return Api().post("tasks", form);
  },
};
