import Api from '@/services/Api'

export default {
    createOne(form){
        return Api().post('services',form)
    },
    getAll(){
        return Api().get('services')
    },
    getAllByCompany(id){
        return Api().get('services/company/'+id)
    },
    downloadFile(id,pos){
        return Api().get('services/download/'+id+'/?file='+pos, {
          responseType: 'blob'
      })
    },
}