import Api from '@/services/Api'

export default {
    createOne (form) {
      return Api().post('companies/create', form)
    },
    getAll(){
        return Api().get('companies')
    },
    deleteOne (id) {
      return Api().put('companies/delete/'+ id)
    },
  }
  