<template>
  <div>
    <CreateButton :create="create" @toggleCreates="showDialog = $event" />
    <b-container>
      <b-row v-if="!create">
        <b-col md="10" lg="12" class="mx-auto">
          <!-- <SelectFor v-model="form.companyId" :input-label="inputLabel" :options="options"/> -->
          <h1>Reporte General</h1>

          <md-card class="text-left" id="user-box">
            <md-card-area md-inset  v-custom-click-outside="hide">
              <a @click="displayUsers()">
                <md-card-header id="detailed-report-user" >
                    <md-avatar v-if="selectedUserObj.username != null">
                      <h2>{{ selectedUserObj.username[0].toUpperCase() }}</h2>
                    </md-avatar>
                  <div class="md-title" v-if="selectedUserObj.username != null">{{selectedUserObj.username}}</div>
                  <div class="md-subhead" v-if="selectedUserObj.position != null">{{selectedUserObj.position}}</div>    
                </md-card-header>                
              </a>
              <md-card-header  v-if="showUsers" class="second-header"> 
                <div id="list-of-users">
                  <div v-for="item in users" :key="item.id" class="user-item" @click="hideUsers(item.id)" :class="{active: item.id == selectedUser}">
                    <md-avatar>
                      <h2>{{ item.username[0].toUpperCase() }}</h2>
                </md-avatar>
                     <div class="md-title">{{item.username}}</div>
                      <div class="md-subhead">{{item.position}}</div>   
                  </div>
                </div> 
              </md-card-header>
            </md-card-area>
          </md-card>

          <md-table v-model="filtered" v-if="filtered.length >= 1">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Fecha Inicio">{{
                toString(item.startDate)
              }}</md-table-cell>
              <md-table-cell md-label="Fecha Fin">{{
                toString(item.endDate)
              }}</md-table-cell>
              <md-table-cell md-label="Total">{{
                getTotal(item.details)
              }}</md-table-cell>
              <md-table-cell md-label="Detalle"><md-button class="md-primary" :href="'detailed/'+item.id">Ver</md-button></md-table-cell>
            </md-table-row>
          </md-table>
          <div v-else>
            <md-empty-state
              md-icon="devices_other"
              md-label="Crea tu Reporte Semanal"
              md-description="Podras agregar las actividades asociadas a los proyectos e indicar tu tiempo empleado en cada una.">
              <md-button class="md-primary md-raised" @click="showDialog = true">Crear</md-button>
            </md-empty-state>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <md-dialog v-if="showDialog" :md-active.sync="showDialog">
      <md-dialog-title> Selectiona la fecha de inicio </md-dialog-title>
      <md-dialog-content>
        La fecha de fin será calculada automáticamente
         <md-datepicker v-model="dialogItem.startDate" :md-disabled-dates="disabledDates">
            <label>Inicio</label>
          </md-datepicker>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button v-if="dialogItem.startDate" class="md-primary" @click="showDialog = false; addNewReport()"
          >Crear</md-button
        >
        <md-button class="md-accent" @click="showDialog = false"
          >Cerrar</md-button
        >

      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
// import SelectFor from "@/components/utils/SelectFor";
// import parse from "date-fns/parse";
import format from "date-fns/format";
import CreateButton from "@/components/utils/CreateButton";
import User from "@/services/User";
import Report from "@/services/time-report/Report";

export default {
  data() {
    return {
      selectedDate: null,
      disabledDates: date => {
        const day = date.getDay()
        return day === 0 || day === 2 || day === 3 || day === 4 || day === 5 || day === 6
      },
      report: {
        companyId: "",
        userId: "",
        startDate: null,
        endDate: null,
        details: [],
      },
      selectedUser: null, 
      create: false,
      showDialog: false,
      dialogItem: {
        userId: "",
        startDate: null,
        endDate: null,
        details: [],
      },
      string: null,
      isLoading: false,
      inputLabel: "Company",
      showUsers: false,
      status: [
        {
          value: "sin_comenzar",
          name: "Sin Comenzar",
        },
        {
          value: "en_progreso",
          name: "En Progreso",
        },
        {
          value: "devuelta",
          name: "Devuelta",
        },
        {
          value: "terminada",
          name: "Terminada",
        },
      ],
      reports: [],
      users:[],
    };
  },
  components: {
    // SelectFor
    CreateButton,
  },
  computed: {
    dateFormat() {
      return this.$material.locale.dateFormat || "dd/MM/yyyy";
    },
    daysLabels() {
      return{
        mon: (new Date(this.selectedDate)).getDate(),
        tue: (new Date((new Date(this.selectedDate)).getTime() + 1 * 86400000)).getDate() ,
        wed: (new Date((new Date(this.selectedDate)).getTime() + 2 * 86400000)).getDate(),
        thu: (new Date((new Date(this.selectedDate)).getTime() + 3 * 86400000)).getDate(),
        fri: (new Date((new Date(this.selectedDate)).getTime() + 4 * 86400000)).getDate(),
        sat: (new Date((new Date(this.selectedDate)).getTime() + 5 * 86400000)).getDate()
      }
      },
    filtered(){
      return this.reports.filter(el => el.userId == this.selectedUser)
    },
    totalTime(){
      let total = 0
      this.filtered[0].details.forEach(el => {
        console.log(el)
        total += Number(el.total) 
      });
      return total
    },
    selectedUserObj(){
      return {
        username: this.users.length > 0 ? this.users[this.users.findIndex(el => el.id == this.selectedUser)].username : null,
        position: this.users.length > 0 ? this.users[this.users.findIndex(el => el.id == this.selectedUser)].position : null
      }
    }
  },
  methods: {
    // toDate(currDate) {
    //   return parse(currDate, this.dateFormat, new Date());
    // },
    toString(currDate) {
      return format(new Date(currDate), this.dateFormat);
    },
    toDay(currDate) {
      return format(new Date(currDate), "dd");
    },
    displayUsers(){
      this.showUsers = true
    },
    hideUsers(id){
      this.selectedUser = id
      this.showUsers = false
    },
    hide: function(){
      this.showUsers = false
    },
    addNewReport(){
      this.isLoading = true
      this.dialogItem.userId = this.$store.state.userId
      this.dialogItem.endDate = new Date((new Date(this.dialogItem.startDate)).getTime() + 5 * 86400000)
      Report.createOne(this.dialogItem)
      .then( result => {
        this.reports.push(result.data.data)
        this.$toast.success("Creado Correctamente");
      })
      .catch(err => {
        console.log(err)
        this.$toast.error("Algo pasó");

      })
      .finally(() => {
        this.isLoading = false
        this.dialogItem = {
        userId: "",
        startDate: null,
        endDate: null,
        details: [],
      }
      })
    },
    // toggleCreate() {
    //   this.create = !this.create;
    // },
    getTotal(obj){
      let total = 0
      obj.forEach(el => {
        total += Number(el.total) 
      });
      return total
    },
    showThisInfo(id) {
      this.showDialog = true;
      this.dialogItem = this.items.filter((el) => el.id == id)[0];
    },
    clearService() {
      this.activity.serviceId = "";
    },
  },
  mounted(){
    this.isLoading = true
    this.selectedUser = this.$store.state.userId
    if(this.$store.state.userRole == 'SUPERCOM'){
      User.getAll().then( result => {
        this.users = result.data.users
      })
      .catch( err => {
        console.log(err)
         this.$toast.error("Algo pasó. Vuelve a Cargar la Página");
      })
    }else{
      this.users = [{
          id: this.$store.state.userId,
          username: this.$store.state.username,
          role: this.$store.state.userRole,
          position: this.$store.state.position
        }]
    }
    Report.getAll()
    .then(result => {
      this.reports = result.data.data
    }).catch(error => {
      console.log(error)
    })
    .finally(()=>{
      this.isLoading = false;
    })
  }
};
</script>

<style src="../../main.css"></style>

