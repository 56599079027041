<template>

  <div id="app">
    <div v-if="$route.name !== 'login' ">
    <Sidebar/>
    <!-- <div v-if="$route.name == 'home'" >
    <Profile/>
    </div> -->
    <div class="app-content" :class="{ 'profile': $route.name == 'home' }">
      <Header/>
      <router-view ></router-view>
    <Footer />
    </div>
      
    </div>
    <div v-else>
      <router-view ></router-view>
    </div>

  </div>
</template>

<script>
import Header from '@/components/partials/Header.vue'
import Footer from '@/components/partials/Footer.vue'
import Sidebar from '@/components/partials/Sidebar.vue'
// import Profile from './components/partials/Profile.vue'0

export default {
  name: 'app',
  components: {
    Header,
    Footer,
    Sidebar,
  },

}
</script>
<style>
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;700&display=swap'); */

html{
   scroll-behavior: smooth !important;
}
body {
  overflow-x: hidden !important;
  background: var(--third) !important;
	/* min-height: calc(100vh - 65px) !important; */
}
/* font-family: 'Source Sans Pro', sans-serif;
font-family: 'Trocchi', serif; */
#app {
  font-family: 'Poppins', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  color: #2c3e50;
}
h1, h2{
  font-family: 'Poppins', sans-serif;
}
h3, h4{
   font-family: 'Source Sans Pro', sans-serif;
}
.app-content{
  min-height: 100vh;
  position: relative;
  /* margin-left: 80px !important; */
  padding-bottom: 2.5rem;
  background-image: linear-gradient(to right, rgb(245 245 245 / 55%), rgb(245 245 245 / 55%)),
  url(assets/imgs/bg-login.svg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
/* .app-content.profile{
  margin-right: 280px;
} */

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}


::-webkit-scrollbar {
	-webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
	width: 0.5rem;
}

::-webkit-scrollbar:horizontal {
	height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  background: black;
}
a{
  color: #2c3e50 !important ;
}
a:hover, a:hover * {
  text-decoration: none !important;
  color: inherit;
}
.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(80 80 80 / 25%);
}
@media (max-width: 1496px) {
  /* .app-content.profile{
  margin-right: auto;
} */
}

</style>
