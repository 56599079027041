import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/login/Login'
import Auth from '@/views/auth/SetPassword'

import ListActivity from '@/views/customer/activity/List' // Customers Application Routes
import ListService from '@/views/customer/service/List' // Customers Application Routes
import ListDocument from '@/views/customer/document/List' // Customers Application Routes
import ListDeliver from '@/views/customer/deliver/List' // Customers Application Routes

import DetailedReport from '@/views/time-report/detailed/List' // Time Report Application
import WeekReport from '@/views/time-report/week/List' // Time Report Application
import GeneralReport from '@/views/time-report/general/List' // Time Report Application
import Summary from '@/views/time-report/summary/List' // Time Report Application

import SalesFunnel from '@/views/sales/funnel/List' // Sales Funnel Application
import ManageUsers from '@/views/users/Manage'
import CreateUsers from '@/views/users/Create'
import ManageCompanies from '@/views/companies/Manage'
import CreateCompanies from '@/views/companies/Create'
import store from '../store'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: (to,from,next) =>{
      if(store.state.canEdit){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/sales/funnel',
    name: 'funnel',
    component: SalesFunnel,
    beforeEnter: (to,from,next) =>{
      if(store.state.canEdit){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/time-report/detailed',
    name: 'detailed',
    component: DetailedReport,
    beforeEnter: (to,from,next) =>{
      if(store.state.canEdit){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/time-report/detailed/:reportId',
    name: 'detailed-report',
    component: DetailedReport,
    beforeEnter: (to,from,next) =>{
      if(store.state.canEdit){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/time-report/general',
    name: 'general',
    component: GeneralReport,
    beforeEnter: (to,from,next) =>{
      if(store.state.canEdit){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/time-report/week',
    name: 'week',
    component: WeekReport,
    beforeEnter: (to,from,next) =>{
      if(store.state.canEdit){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/time-report/summary',
    name: 'summary',
    component: Summary,
    beforeEnter: (to,from,next) =>{
      if(store.state.isSupercom){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/customer/activity',
    name: 'activity',
    component: ListActivity
  },  
  {
    path: '/customer/service',
    name: 'service',
    component: ListService
  }, 
  {
    path: '/customer/document',
    name: 'document',
    component: ListDocument
  },  
  {
    path: '/customer/deliver',
    name: 'deliver',
    component: ListDeliver
  }, 
  {
    path: '/users',
    name: 'users',
    component: ManageUsers,
    beforeEnter: (to,from,next) =>{
      if(store.state.isSupercom){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: CreateUsers,
    beforeEnter: (to,from,next) =>{
      if(store.state.isSupercom){
        next()
      }else{
        next({name: 'login'})
      }
    }
  }, 
  {
    path: '/companies',
    name: 'companies',
    component: ManageCompanies,
    beforeEnter: (to,from,next) =>{
      if(store.state.isSupercom){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/companies/create',
    name: 'companies-create',
    component: CreateCompanies,
    beforeEnter: (to,from,next) =>{
      if(store.state.isSupercom){
        next()
      }else{
        next({name: 'login'})
      }
    }
  }, 
  {
    path: '/account',
    name: 'account',
    component: Auth
  },  
  {
    path: '*',
    redirect: '/'
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // let expiresIn = 0;
  // if(store.state.expiration){
  // const now = new Date();
  // expiresIn = (new Date(store.state.expiration)).getTime() - now.getTime();
  // }
  if (to.name !== 'login' && to.name !== 'account' && !store.state.isAuthenticated) next({ name: 'login' })
  else next()
})

export default router
