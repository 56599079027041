import Api from "@/services/Api";

export default {
  getAll() {
    return Api().get("sales");
  },
  getByUserId(id) {
    return Api().get("sales/" + id);
  },
  deleteById(id) {
    return Api().put("sales/delete/" + id);
  },
  updateOne(id,form) {
    return Api().put("sales/update/"+id, form);
  },
  createOne(form) {
    return Api().post("sales", form);
  },
};
