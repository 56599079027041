import Api from "@/services/Api";

export default {
  getAll() {
    return Api().get("projects");
  },
  getOneById(id) {
    return Api().get("projects/" + id);
  },
  deleteById(id) {
    return Api().put("projects/delete/" + id);
  },
  updateOneById(id,project) {
    return Api().put("projects/update/"+id,project);
  },
  createOne(form) {
    return Api().post("projects", form);
  },
};
