<template>
  <div class="document-list">
         <CreateButton :create="create" @toggleCreates="create = $event"/>
    <b-container>
      <b-row v-if="!create">
        <b-col md="10" class="mx-auto">
          <h1>Documentos</h1>
          <div v-if="!isLoading">
          <md-table
            v-model="items"
            md-sort="createdAt"
            md-sort-order="asc"
            v-if="items.length > 0"
            class="customer"
          >
            <md-table-toolbar>
              <h1 class="md-title text-left">Complement Services</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }" @click="showThisInfo(item.id)">
              <md-table-cell md-label="Fecha" md-sort-by="createdAt">{{
                toString(item.createdAt)
              }}</md-table-cell>
              <md-table-cell md-label="Compañia" md-sort-by="companyId"  v-if="$store.state.canEdit">{{
                companies[companies.findIndex((e) => e.id == item.companyId)]
                  .name
              }}</md-table-cell>
              <md-table-cell md-label="Servicio" md-sort-by="name">{{
                services[services.findIndex((e) => e.id == item.serviceId)].name
              }}</md-table-cell>
              <md-table-cell md-label="Subido Por" md-sort-by="companyId">{{
                users[users.findIndex((e) => e.id == item.userId)]
                  .username
              }}</md-table-cell>
              <md-table-cell md-label="Detalle" md-sort-by="details">{{
                item.details
              }}</md-table-cell>
              <md-table-cell md-label="Archivo" md-sort-by="document"
                ><md-button @click="downloadThis(item.id)"
                  ><font-awesome-icon :icon="['fas', 'file-pdf']"/></md-button
              ></md-table-cell>
              <md-table-cell md-label="Comentarios" md-sort-by="comments">{{
                item.comments
              }}</md-table-cell>
            </md-table-row>
          </md-table>
           <div v-else>
            <md-empty-state
              md-icon="devices_other"
              md-label="Crea tu primer documento"
              md-description="Agrega un soporte junto con el detalle">
              <md-button class="md-primary md-raised" @click="create = true">Crear</md-button>
            </md-empty-state>
          </div>
          </div>
          <div class="text-center" v-else><b-spinner></b-spinner></div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="8" sm="10" lg="6" class="mx-auto">
          <h1>Crear Documento</h1>
          <md-card>
            <md-card-content>
              <form @submit.prevent="submitForm" enctype="multipart/form-data">
                <b-form-group>
                  <md-field  v-if="$store.state.canEdit">
                    <md-select
                      v-model="document.companyId"
                      placeholder="Seleccione una compañia"
                      @input="clearService()"
                    >
                      <md-option
                        v-for="(item, idx) in companies"
                        :key="'company-id-act-' + idx"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                  <md-field v-if="document.companyId">
                    <md-select
                      v-model="document.serviceId"
                      placeholder="Seleccione un servicio"
                    >
                      <md-option
                        v-for="(item, idx) in services.filter(
                          (e) => e.companyId == document.companyId
                        )"
                        :key="'service-id-act-' + idx"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                  <md-field>
                    <label>Detalle</label>
                    <md-input
                      v-model="document.details"
                      md-counter="100"
                    ></md-input>
                  </md-field>
                  <md-field>
                    <label>Documento</label>
                    <md-file v-model="document.document.name" @md-change="selectFile($event)"/>
                  </md-field>
                  <md-field>
                    <label>Comentarios</label>
                    <md-textarea
                      v-model="document.comments"
                      md-autogrow
                    ></md-textarea>
                  </md-field>

                  <md-button type="submit" class="create-button">Crear</md-button>
                </b-form-group>
              </form>
            </md-card-content>
          </md-card>
        </b-col>
      </b-row>
    </b-container>
    <md-dialog v-if="showDialog" :md-active.sync="showDialog">
      <md-dialog-title>Agrega comentarios y/o configura tu documento</md-dialog-title>
      <md-dialog-content>
        <md-field>
          <label>Comentarios</label>
          <md-textarea v-model="dialogItem.comments" md-autogrow></md-textarea>
        </md-field>
        <div class="text-center">
        <md-button class="md-primary" @click="setUpAsDeliverDocument()" :disabled="dialogItem.isDeliverable"  v-if="$store.state.canEdit">Asignar como entregable</md-button>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-raised md-primary" @click="updateDocument(dialogItem.id)"
          >Actualizar</md-button
        >
        <md-button class="md-accent" @click="showDialog = false"
          >Cerrar</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
// import DynamicTable from '@/components/utils/DynamicTable'
import format from "date-fns/format"
import CreateButton from "@/components/utils/CreateButton";
import Document from "@/services/customer/Document"
import Service from "@/services/customer/Service";
import Deliver from "@/services/customer/Deliver";
import Company from "@/services/Company";
import User from "@/services/User";

export default {
  data() {
    return {
      create: false,
      isLoading: false,
      document: {
        createdAt: "",
        details: "",
        companyId: "",
        serviceId: "",
        document: { name: "" },
        comments: "",
      },
      showDialog: false,
      dialogItem: {},
      titles: ["servicio", "oferta mercantil", "acta de inicio", "propuesta"],
      items: [],
      services: [],
      companies: [],
      users: []
    };
  },
  components: {
    CreateButton
  },
  methods: {
    downloadThis(id) {
      Document.downloadFile(id).then((response) => {
        const ext = this.items[this.items.findIndex(e => e.id === id)].document.split('.').pop()
        const url = URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id + "." + ext);
        document.body.appendChild(link);
        link.click();
      });
    },
    selectFile(evt){
      this.document.document = evt[0];
    },
    showThisInfo(id) {
      this.showDialog = true;
      this.dialogItem = this.items.filter((el) => el.id == id)[0];
    },
    submitForm() {
      this.isLoading = true
      let formData = new FormData()
      // formData.append('createdAt', new Date())
      if(this.document.serviceId && this.document.details && this.document.document.name){
      if(this.$store.state.canEdit){
        formData.append('companyId',this.document.companyId)
      }else{
        formData.append('companyId',this.$store.state.companyId)
      }
      formData.append('details',this.document.details)
      formData.append('serviceId',this.document.serviceId)
      formData.append('userId',this.$store.state.userId)
      formData.append('file',this.document.document)
      formData.append('comments',this.document.comments)
      Document.createOne(formData)
        .then((result) => {
          this.create = false
            this.items.push(result.data.data)
            this.$toast.success("Creado Correctamente")
        }).catch((err) => {
            this.$toast.error("Algo pasó")
            console.log(err)
        }).finally(() => {
          this.isLoading = false
          this.resetForm();
        })
        }else{
      this.$toast.error("Diligencia los campos")
      this.isLoading = false;
    }
    },
    resetForm() {
      this.document = {
        details: "",
        companyId: "",
        serviceId: "",
        document: { name: "" },
        comments: "",
      };
    },
    setUpAsDeliverDocument(){
      this.isLoading = true
      const deliverToCreate = {
        companyId: this.dialogItem.companyId,
        serviceId: this.dialogItem.serviceId,
        userId: this.$store.state.userId,
        details: this.dialogItem.details,
        deliverDocument: this.dialogItem.document
      }
      Deliver.createOne(deliverToCreate)
      .then(() => {
        this.$toast.success("Asignado Correctamente")
        this.dialogItem.isDeliverable = true
      })
      .catch( error => {
        this.$toast.error("Algo pasó")
        console.log(error)
      })
      .finally(() =>{
        this.isLoading = false
      })
    },
    async updateDocument(id){
      try {
        const keysToUpdate = {
            comments: this.dialogItem.comments,
            isDeliverable: this.dialogItem.isDeliverable
        }
        await Document.updateOne(id, keysToUpdate)
      }catch(error){
        this.$toast.error("Algo pasó")
        console.log(error)
      }
      this.$toast.success("Actualizado Correctamente")
      this.showDialog = false
    },
    clearService() {
      this.document.serviceId = "";
    },
    toString(currDate) {
      return format(new Date(currDate),this.$material.locale.dateFormat);
    },
  },
  async mounted(){
    this.isLoading = true
    try{
      this.items = (await Document.getAll()).data.data
      this.companies = (await Company.getAll()).data.companies
      this.services = (await Service.getAll()).data.data
      if(!this.$store.state.canEdit){
        this.services = this.services.filter(el => el.companyId == this.$store.state.companyId)
        this.items = this.items.filter(el => el.companyId == this.$store.state.companyId)
        this.document.companyId = this.$store.state.companyId
      }
      this.users = (await User.getAll()).data.users
    }catch(error){
      console.log(error)
    }
    this.isLoading = false
  }
};
</script>

<style></style>
