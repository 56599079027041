<template>
  <div>
    <b-container>
      <b-row >
        <b-col md="10" lg="12" class="mx-auto">
          <!-- <SelectFor v-model="form.companyId" :input-label="inputLabel" :options="options"/> -->
          <h1>Reporte Semanal</h1>

          <md-card class="text-left" id="user-box" v-if="!isLoading">
            <md-card-area md-inset >
              <md-card-header id="date-selector">
                <md-datepicker v-model="selectedDate" :md-disabled-dates="disabledDates">
                  <label>Seleccione una Fecha</label>
                </md-datepicker>
              </md-card-header>          
            </md-card-area>
            <md-card-content v-if="selectedDate" id="week-of-users">
            <md-card-header  v-for="item in filtered" :key="item.id">
                    <md-avatar>
                    <font-awesome-icon :icon="['fas', 'user-circle']" />
                  </md-avatar>
                  <div class="md-title">{{item.username}}</div>
                  <div class="md-subhead">{{item.position}}</div>    
                  <div class="hours"> {{item.total}} hrs</div>
                </md-card-header>      
                </md-card-content>
          </md-card>
          <div class="text-center" v-else><b-spinner></b-spinner></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import User from "@/services/User";
import Report from "@/services/time-report/Report";
import format from "date-fns/format";

export default {
  data() {
    return {
      selectedDate: null,
      disabledDates: date => {
        const day = date.getDay()
        return day === 0 || day === 2 || day === 3 || day === 4 || day === 5 || day === 6
      },
      isLoading: false,
      showUsers: false,
      reports: [],
      users: []
    };
  },
  components: {
    // SelectFor
    // CreateButton,
  },
  computed: {
    dateFormat() {
      return this.$material.locale.dateFormat || "dd/MM/yyyy";
    },
    daysLabels() {
      return{
        mon: (new Date(this.selectedDate)).getDate(),
        tue: (new Date((new Date(this.selectedDate)).getTime() + 1 * 86400000)).getDate() ,
        wed: (new Date((new Date(this.selectedDate)).getTime() + 2 * 86400000)).getDate(),
        thu: (new Date((new Date(this.selectedDate)).getTime() + 3 * 86400000)).getDate(),
        fri: (new Date((new Date(this.selectedDate)).getTime() + 4 * 86400000)).getDate(),
        sat: (new Date((new Date(this.selectedDate)).getTime() + 5 * 86400000)).getDate()
      }
      },
    filtered(){
      const reportsFitlered = this.reports.filter(el => this.toString(el.startDate) == this.toString(this.selectedDate))
      let newUsers = this.users.map( function(obj) {
        let reportElement = reportsFitlered.findIndex(el => el.userId == obj.id)
        obj.total = 0
          if(reportElement != -1){
              reportsFitlered[reportElement].details.forEach(el => {
                obj.total += Number(el.total) 
              })
          }
          return obj
      } )
      // console.log(newUsers)
      return newUsers
    },
  },
  methods: {
   toString(currDate) {
      return format(new Date(currDate), this.dateFormat);
    },
  },
  async mounted(){
    this.isLoading = true
    try{
        this.users = (await User.getAll()).data.users
      }catch(err) {
        console.log(err)
        this.$toast.error("Algo pasó. Vuelve a Cargar la Página");
      }
      try{
         this.reports = (await Report.getAll()).data.data
      }catch(error) {
        console.log(error)
        this.$toast.error("Algo pasó. Vuelve a Cargar la Página");

      }
      this.isLoading = false
  }
};
</script>

<style src="../../main.css"></style>

