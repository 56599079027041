<template>
  <footer class="foot">
    <span>
      © {{year}} Copyright:
      Complement Services
    </span>
  </footer>
  
</template>

<script>
export default {
  data(){
    return{
      year: new Date().getFullYear()
    }
  }
};
</script>

<style >


</style>