import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue } from "bootstrap-vue";
import VueMaterial from "vue-material";
import VueSwal from "vue-swal";
import Toast from "vue-toastification";
// Import the CSS or use your own!
// import loader from "vue-ui-preloader";
import store from "@/store";
import "es6-promise/auto";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

import "vue-toastification/dist/index.css";

library.add(fas);
library.add(fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(BootstrapVue);
Vue.use(VueMaterial);
// Vue.use(loader);
Vue.use(Vuex);
Vue.use(VueSwal);
const toastOptions = {
  position: "top-center",
  timeout: 3962,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};
Vue.use(Toast, toastOptions);
Vue.config.productionTip = false;
Vue.material.locale.dateFormat = "dd/MM/yyyy";
Vue.material.locale.firstDayOfAWeek = 1;
// localStorage._language = navigator.language
// export const languageBus = new Vue();
Vue.directive("custom-click-outside", {
  bind: function (el, binding, vnode) {
    console.log(el)
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
  stopProp(event) {
    event.stopPropagation()
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
