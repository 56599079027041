<template>
  <div class="service-list">
    <CreateButton :create="create" @toggleCreates="create = $event" v-if="$store.state.canEdit" />
    <b-container>
      <b-row v-if="!create">
        <b-col md="10" sm="10" lg="8" class="mx-auto">
          <h1>Servicios</h1>
          <div v-if="!isLoading">
          <md-table v-model="services" md-sort="name" md-sort-order="asc" v-if="services.length > 0" class="customer">
            <md-table-toolbar>
              <h1 class="md-title text-left">Complement Services</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Compañia" md-sort-by="companyId" v-if="$store.state.canEdit">{{
                companies[companies.findIndex((e) => e.id == item.companyId)]
                  .name
              }}</md-table-cell>
              <md-table-cell md-label="Servicio" md-sort-by="name">{{
                item.name
              }}</md-table-cell>
              <md-table-cell md-label="Oferta Mercantil" md-sort-by="offer"
                ><md-button @click="downloadThis(item.id, 'off')"
                  ><font-awesome-icon :icon="['fas', 'user-tie']"/></md-button
              ></md-table-cell>
              <md-table-cell md-label="Acta de inicio" md-sort-by="certificate"
                ><md-button @click="downloadThis(item.id, 'cert')"
                  ><font-awesome-icon :icon="['fas', 'handshake']"/></md-button
              ></md-table-cell>
              <md-table-cell md-label="Propuesta" md-sort-by="submission"
                ><md-button @click="downloadThis(item.id, 'sub')"
                  ><font-awesome-icon :icon="['fas', 'tasks']"/></md-button
              ></md-table-cell>
            </md-table-row>
          </md-table>
          <div v-else>
            <md-empty-state
              md-icon="devices_other"
              md-label="Crea tu primer servicio"
              md-description="Agrega el soporte y el detalle respectivo" v-if="$store.state.canEdit">
              <md-button class="md-primary md-raised" @click="create = true">Crear</md-button>
            </md-empty-state>
            <md-empty-state
              md-icon="feedback"
              md-label="Aun no tienes servicios asociados"
              md-description="Muy pronto podras visualizarlos" v-else>
            </md-empty-state>
          </div>
          </div>
          <div class="text-center" v-else><b-spinner></b-spinner></div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="8" sm="10" lg="6" class="mx-auto">
          <h1>Crear servicio</h1>
          <md-card>
            <md-card-content>
              <form @submit.prevent="submitForm" enctype="multipart/form-data">
                <b-form-group>
                  <md-field>
                    <md-select
                      v-model="service.companyId"
                      placeholder="Seleccione una compañia"
                    >
                      <md-option
                        v-for="(item, idx) in companies"
                        :key="'company-id-' + idx"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                  <md-field>
                    <label>Nombre del Servicio</label>
                    <md-input v-model="service.name" md-counter="50"></md-input>
                  </md-field>
                  <md-field>
                    <label>Oferta Mercantil</label>
                    <md-file v-model="service.offer.name" @md-change="selectOfferFile($event)"/>
                  </md-field>
                  <md-field>
                    <label>Acta de Inicio</label>
                    <md-file v-model="service.certificate.name" @md-change="selectCertFile($event)"/>
                  </md-field>
                  <md-field>
                    <label>Propuesta</label>
                    <md-file v-model="service.submission.name" @md-change="selectSubFile($event)"/>
                  </md-field>
                  <md-button type="submit" class="create-button">Crear</md-button>
                </b-form-group>
              </form>
            </md-card-content>
          </md-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- <DynamicTable :data="items" :head-labels="titles"/> -->
  </div>
</template>

<script>
// import DynamicTable from '@/components/utils/DynamicTable'
import CreateButton from "@/components/utils/CreateButton";
import Company from "@/services/Company"
import Service from "@/services/customer/Service"

export default {
  data() {
    return {
      create: false,
      isLoading: false,
      titles: ["servicio", "oferta mercantil", "acta de inicio", "propuesta"],
      items: [],
      companies: [],
      service: {
        name: "",
        companyId: "",
        offer: { name: "" },
        certificate: { name: "" },
        submission: { name: "" },
      },
      services: []
    };
  },
  components: {
    CreateButton
  },
  methods: {
    downloadThis(id, pos) {
      Service.downloadFile(id,pos).then((response) => {
        let currentItem = this.services[this.services.findIndex(e => e.id === id)]
        let ext = ""
        if(pos == "off"){
          ext = currentItem.offer.split('.').pop()
        }else if(pos == "cert"){
          ext = currentItem.certificate.split('.').pop()
        }else{
          ext = currentItem.submission.split('.').pop()
        }
        const url = URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id + "-" + pos + "." + ext);
        document.body.appendChild(link);
        link.click();
      });
    },
    selectOfferFile(evt){
      this.service.offer = evt[0];
    },
    selectCertFile(evt){
      this.service.certificate = evt[0];
    },
    selectSubFile(evt){
      this.service.submission = evt[0];
    },
    submitForm(){
      this.isLoading = false
      if(this.service.name && this.service.offer && this.service.certificate.name && this.service.submission.name && this.service.certificate.name  && this.service.companyId){
      let formData = new FormData()
      formData.append('name',this.service.name)
      formData.append('companyId',this.service.companyId)
      formData.append('offer',this.service.offer)
      formData.append('certificate',this.service.certificate)
      formData.append('submission',this.service.submission)
      Service.createOne(formData)
        .then((result) => {
            this.$toast.success("Creado Correctamente")
            this.create = false
            this.services.push(result.data.data)
        }).catch((err) => {
            this.$toast.error("Algo pasó")
            console.log(err)
        }).finally(() => {
            this.create = false
          this.isLoading = false
          this.resetForm();
        })
      }else{
      this.$toast.error("Diligencia los campos")
      this.isLoading = false;
    }
    },
    resetForm(){
      this.service = {
        name: "",
        companyId: "",
        offer: { name: "" },
        certificate: { name: "" },
        submission: { name: "" },
      }
    }
  },
  computed:{
  },
  async mounted(){
    this.isLoading = true
    try {
      this.companies = (await Company.getAll()).data.companies
      this.services = (await Service.getAll()).data.data
      console.log(this.$store.state.companyId)
      if(!this.$store.state.canEdit){
        this.services = this.services.filter(el => el.companyId == this.$store.state.companyId)
      }
    } catch (error) {
      console.log(error)
    }
    this.isLoading = false
  }
};
</script>

<style src="../../main.css"></style>

