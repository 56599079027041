import Api from "@/services/Api";

export default {
  getAll() {
    return Api().get("reports");
  },
  getOneById(id) {
    return Api().get("reports/" + id);
  },
  deleteById(id) {
    return Api().put("reports/delete/" + id);
  },
  updateOneById(id,report) {
    return Api().put("reports/update/"+id,report);
  },
  createOne(form) {
    return Api().post("reports", form);
  },
};
