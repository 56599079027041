<template>
  <div class="cfs-client">
    <md-field :class="getValidationClass('name')">
      <label>Nombre</label>
      <md-input
        v-model="clientItem.name"
      ></md-input>
      <span class="md-error" v-if="!$v.clientItem.name.required"
        >El nombre es requerido</span
      >
    </md-field>
    <md-field :class="getValidationClass('code')">
      <label>Código</label>
      <md-input
        v-model="clientItem.code"
      ></md-input>
      <span class="md-error" v-if="!$v.clientItem.code.required"
        >El código es requerido</span
      >
    </md-field>
    <md-button class="md-raised client-button" @click.stop="sendclientItem()"
      >Crear Cliente</md-button
    >
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "CreateClient",
  mixins: [validationMixin],
  props: {
    userId: String,
  },
  data() {
    return {
      clientItem: {
        userId: this.userId,
        name: null,
        code: null,
      },
    };
  },
  validations: {
    clientItem: {
      name: {
        required,
      },
      code: {
        required,
      },
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.clientItem[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    sendclientItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$emit("newClientEvent", this.clientItem);
      }
    },
  },
};
</script>

<style>
.cfs-client {
  padding: 4px 32px 4px 32px;
}
.client-button {
  border-radius: 6px !important;
  margin: 6px 0 !important;
  width: 100%;
  color: #fff !important;
  background: var(--first) !important;
}
</style>
