<template>
  <div>
    <!-- <CreateButton :create="create" @toggleCreates="showDialog = $event" /> -->
    <b-container>
      <b-row v-if="!create">
        <b-col md="10" lg="12" class="mx-auto">
          <!-- <SelectFor v-model="form.companyId" :input-label="inputLabel" :options="options"/> -->
          <h1>Embudo de Ventas</h1>

          <md-card class="text-left" id="user-box">
            <md-card-area md-inset v-custom-click-outside="hide">
              <a @click="displayUsers()">
                <md-card-header id="detailed-report-user">
                  <md-avatar>
                    <font-awesome-icon :icon="['fas', 'user-circle']" />
                  </md-avatar>
                  <div class="md-title">
                    {{
                      users[users.findIndex((el) => el.id == selectedUser)]
                        .username
                    }}
                  </div>
                  <div class="md-subhead">
                    {{
                      users[users.findIndex((el) => el.id == selectedUser)]
                        .position
                    }}
                  </div>
                </md-card-header>
              </a>
              <md-card-header id="header-list-users" :class="{'show-users': showUsers && users.length > 1}">
                <div id="list-of-users">
                  <div
                    v-for="item in users"
                    :key="'user' + item.id"
                    class="user-item"
                    @click="hideUsers(item.id)"
                    :class="{ active: item.id == selectedUser }"
                  >
                    <md-avatar>
                      <font-awesome-icon :icon="['fas', 'user-circle']" />
                    </md-avatar>
                    <div class="md-title">{{ item.username }}</div>
                    <div class="md-subhead">{{ item.position }}</div>
                  </div>
                </div>
              </md-card-header>
            </md-card-area>

            <md-card-content id="date-selector" class="text-center" v-if="filtered.length > 0">
            <md-button class="md-primary" @click="showDialog = true"
                >Agregar Venta</md-button
              >
              <md-button class="md-accent" @click="showDiagram = !showDiagram"
                ><span v-if="!showDiagram">Ver Embudo</span><span v-else>Ver Tabla</span></md-button>
                </md-card-content>
          </md-card>

          <md-table v-model="filtered" v-if="filtered.length >= 1 && showDiagram == false">
            <md-table-row slot="md-table-row" slot-scope="{ item }" @click="showThisInfo(item.id)">
              <md-table-cell md-label="Ciente">{{
                item.clientName
              }}</md-table-cell>
              <md-table-cell md-label="Linea">{{
               types[types.findIndex(obj => obj.value == item.type)].label
              }}</md-table-cell>
              <md-table-cell md-label="Detalle">{{
                item.description
              }}</md-table-cell>
              <md-table-cell md-label="Fecha Inicial">{{
                toString(item.startDate)
              }}</md-table-cell>
              <md-table-cell md-label="Fecha Estimada de Cierre">{{
                toString(item.estimatedEndDate)
              }}</md-table-cell>
              <md-table-cell md-label="Monto">{{ formatAmount(item.amount) }}</md-table-cell>
              <md-table-cell
                md-label="Probabilidad (%)"
                :class="'prob-' + item.probability"
                >{{  probabilities[probabilities.findIndex(obj => obj.value == item.probability)].label }}</md-table-cell
              >
              <md-table-cell
                md-label="Estado"
                :class="'stat-' + item.status"
                >{{ status[status.findIndex(obj => obj.value == item.status)].label }}</md-table-cell
              >
              <md-table-cell md-label="Observaciones">{{
                item.comments
              }}</md-table-cell>
              <md-table-cell md-label="Dias">{{
                countDays(item.startDate)
              }}</md-table-cell>
            </md-table-row>
          </md-table>
          <div v-if="filtered.length == 0">
            <md-empty-state
              md-icon="devices_other"
              md-label="Agrega tus ventas"
              md-description="Puedes reportar tus ventas, indicar el estado, la probabilidad y a que linea pertenecen"
            >
              <md-button class="md-primary md-raised" @click="showDialog = true">Crear</md-button>
            </md-empty-state>
          </div>
          <div v-if="showDiagram == true && filtered.length >= 1" id="funnel-report"> 
            <vue-funnel-graph :width="width" :height="height" :labels="labels"
              :values="values" :colors="colors" :sub-labels="subLabels" :direction="direction"
              :gradient-direction="gradientDirection"
              :animated="true" :display-percentage="true"
            ></vue-funnel-graph>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <md-dialog v-if="showDialog" :md-active.sync="showDialog" id="create-sales-dialog">
      <md-dialog-title> Llene los siguientes campos </md-dialog-title>
      <md-dialog-content>
        <md-field>
          <label>Cliente</label>
          <md-input v-model="dialogItem.clientName" 
          />
        </md-field>
        <md-field v-if="dialogItem.clientName">
          <label>Linea</label>
          <md-select v-model="dialogItem.type" >
            <md-option
              v-for="(item, idx) in types"
              :key="'type-modal-act-' + idx"
              :value="item.value"
              >{{ item.label }}</md-option
            >
          </md-select>
        </md-field>
        <md-field v-if="dialogItem.type">
          <label>Detalle</label>
          <md-input v-model="dialogItem.description" />
        </md-field>
          <md-datepicker v-if="dialogItem.description" v-model="dialogItem.startDate" :md-disabled-dates="disabledDates">
            <label>Fecha inicio</label>
          </md-datepicker>
          <md-datepicker v-if="dialogItem.startDate" v-model="dialogItem.estimatedEndDate" :md-disabled-dates="disabledDates">
            <label>Fecha estimada de entrega</label>
          </md-datepicker>
        <md-field v-if="dialogItem.estimatedEndDate">
          <label>Monto</label>
          <md-input type="number" v-model="dialogItem.amount"/>
        </md-field>
        <md-field v-if="dialogItem.amount">
          <label>Probabilidad</label>
          <md-select v-model="dialogItem.probability" >
            <md-option
              v-for="(item, idx) in probabilities"
              :key="'prob-modal-act-' + idx"
              :value="item.value"
              >{{ item.label }}</md-option
            >
          </md-select>
        </md-field>
        <md-field v-if="dialogItem.probability">
          <label>Estado</label>
          <md-select v-model="dialogItem.status" >
            <md-option
              v-for="(item, idx) in status"
              :key="'stat-modal-act-' + idx"
              :value="item.value"
              >{{ item.label }}</md-option
            >
          </md-select>
        </md-field>
        <md-field v-if="dialogItem.status">
          <label>Comentarios</label>
          <md-textarea v-model="dialogItem.comments">
          </md-textarea>
        </md-field>

      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          v-if="dialogItem.status"
          class="md-primary"
          @click="
            showDialog = false;
            addNewSale();
          "
          >Crear</md-button
        >
        <md-button class="md-accent" @click="showDialog = false"
          >Cerrar</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <md-dialog v-if="showUpdateItemDialog" :md-active.sync="showUpdateItemDialog">
      <md-dialog-title>Información de la Venta </md-dialog-title>
      <md-dialog-content>
        <md-field>
          <md-select v-model="toUpdateItem.probability" placeholder="Probabilidad">
            <md-option
              v-for="(item, idx) in probabilities"
              :key="'prob-sales-id-act-' + idx"
              :value="item.value"
              >{{ item.label }}</md-option
            >
          </md-select>
        </md-field>
        <md-field>
          <md-select v-model="toUpdateItem.status" placeholder="Estado">
            <md-option
              v-for="(item, idx) in status"
              :key="'status-sales-id-act-' + idx"
              :value="item.value"
              >{{ item.label }}</md-option
            >
          </md-select>
        </md-field>
        <md-field>
          <label>Observaciones</label>
          <md-textarea v-model="toUpdateItem.comments" md-autogrow></md-textarea>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-raised md-primary" @click="updateSale(toUpdateItem.id)"
          >Actualizar</md-button
        >
        <md-button class="md-accent" @click="showDialog = false"
          >Cerrar</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
// import SelectFor from "@/components/utils/SelectFor";
// import parse from "date-fns/parse";
import format from "date-fns/format";
// import CreateButton from "@/components/utils/CreateButton";
import Sales from "@/services/sales/Sales-Funnel";
import User from "@/services/User";
import { VueFunnelGraph } from 'vue-funnel-graph-js';


export default {
  data() {
    return {
      selectedDate: null,
      showDiagram: false,
      showUpdateItemDialog: false,
      disabledDates: (date) => {
        const day = date.getDay();
        return (
          day === 0 ||
          day === 6
        );
      },
      probabilities: [
        { value: "0", label: 0 },
        { value: "25", label: 25 },
        { value: "50", label: 50 },
        { value: "75", label: 75 },
        { value: "100", label: 100 },
      ],
      types: [
        { value: "outsourcing", label: "Outsourcing Contable" },
        { value: "valoraciones", label: "Valoraciones" },
        { value: "impuestos", label: "Impuestos" },
        { value: "auditoria", label: "Auditoria y Revisoria Fiscal" },
        { value: "otras", label: "Otras consultorias contables" },
        { value: "herramienta", label: "Herramientas Tecnologicas" },
      ],
      status: [
        { value: "identificada", label: "Identificada" },
        { value: "en_proceso", label: "En Proceso" },
        { value: "entregada", label: "Entregado al Cliente" },
        { value: "ganada", label: "Ganada" },
        { value: "perdida", label: "Perdida" },
      ],
      selectedUser: null,
      create: false,
      showDialog: false,
      dialogItem: {
        userId: "",
        clientName: "",
        type: "",
        description: "",
        startDate: null,
        estimatedEndDate: null,
        amount: "",
        probability: "",
        state: "",
        comments: "",
        days: "",
      },
      toUpdateItem: {},
      string: null,
      isLoading: false,
      inputLabel: "Company",
      showUsers: false,
      sales: [],
      users: [],
      labels: ['Identificadas', 'En Proceso', 'Enviadas', 'Ganadas', 'Perdidas'],
      subLabels: ['Outsourcing Contable', 'Valoraciones', 'Impuestos', 'Auditoria y Revisoria Fiscal', 
                'Herramientas Tecnologicas','Otras Herramientas Contables'],
      colors: [
          ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
          ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
          ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
      ],
      direction: 'horizontal',
      gradientDirection: 'horizontal',
      height: 300,
      width: 860
    };
  },
  components: {
    // SelectFor
    // CreateButton,
    VueFunnelGraph
  },
  computed: {
    dateFormat() {
      return this.$material.locale.dateFormat || "dd/MM/yyyy";
    },
    filtered(){
      if(this.sales.length > 0){
        return this.sales.filter((el) => el.userId == this.selectedUser);
      }else{
        return []
      }
    },
    values(){
      let salesFunnel = {
        identificada: {
        outsourcing: 0,
        valoraciones: 0,
        impuestos: 0,
        auditoria: 0,
        herramienta: 0,
        otras: 0
      },
        en_proceso: {
        outsourcing: 0,
        valoraciones: 0,
        impuestos: 0,
        auditoria: 0,
        herramienta: 0,
        otras: 0
      },
        entregada: {
        outsourcing: 0,
        valoraciones: 0,
        impuestos: 0,
        auditoria: 0,
        herramienta: 0,
        otras: 0
      },
        ganada: {
        outsourcing: 0,
        valoraciones: 0,
        impuestos: 0,
        auditoria: 0,
        herramienta: 0,
        otras: 0
      },
        perdida: {
        outsourcing: 0,
        valoraciones: 0,
        impuestos: 0,
        auditoria: 0,
        herramienta: 0,
        otras: 0
      }
      }  
      this.filtered.forEach(element => {
        salesFunnel[element.status][element.type] += 1
      });
      let matrixValues = []
      Object.keys(salesFunnel).forEach(key => {
        matrixValues.push(Object.values(salesFunnel[key]))
      })
      return matrixValues

    }
  },
  methods: {
    // toDate(currDate) {
    //   return parse(currDate, this.dateFormat, new Date());
    // },
    formatAmount(amount){
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    countDays(currDate) {
      return Math.floor((new Date().getTime()-new Date(currDate).getTime()) / (1000 * 3600 * 24))
    },
    toString(currDate) {
      return format(new Date(currDate), this.dateFormat);
    },
    displayUsers() {
      this.showUsers = true;
    },
    showThisInfo(id) {
      this.showUpdateItemDialog = true;
      this.toUpdateItem = this.sales.filter((el) => el.id == id)[0];
    },
    hideUsers(id) {
      this.selectedUser = id;
      this.showUsers = false;
    },
    hide: function() {
      this.showUsers = false;
    },
    addNewSale() {
      this.isLoading = true
      this.dialogItem.userId = this.selectedUser
      Sales.createOne(this.dialogItem)
      .then((result) => {
        this.$toast.success("Creado con éxito");
        this.sales.push(result.data.data);
      })
      .catch((err) => {
        console.log(err)
        this.$toast.error("Algo pasó. Vuelve a Cargar la Página");
      })
      .finally(() => {
        this.dialogItem = {
        userId: "",
        clientName: "",
        type: "",
        description: "",
        startDate: null,
        estimatedEndDate: null,
        amount: "",
        probability: "",
        state: "",
        comments: "",
        days: "",
      };
        this.isLoading = false
      })
    },
     async updateSale(id){
      try {
        const keysToUpdate = {
            probability: this.toUpdateItem.probability,
            status: this.toUpdateItem.status,
            comments: this.toUpdateItem.comments           
        }
        await Sales.updateOne(id, keysToUpdate)
      }catch(error){
        this.$toast.error("Algo pasó")
        console.log(error)
      }
      this.$toast.success("Actualizado Correctamente")
      this.showUpdateItemDialog = false
    },
  },
  mounted(){
    this.isLoading = true;
    this.selectedUser = this.$store.state.userId;
    if(this.$store.state.userRole == 'SUPERCOM'){
      User.getAll().then( result => {
        this.users = result.data.users
      })
      .catch( err => {
        console.log(err)
         this.$toast.error("Algo pasó. Vuelve a Cargar la Página");
      })
      Sales.getAll().then( result => {
        this.sales = result.data.data
      }).catch( err => {
        console.log(err)
         this.$toast.error("Algo pasó. Vuelve a Cargar la Página");

      }).finally( () => {
        this.isLoading = false;
      })
    }else{
      Sales.getByUserId(this.$store.state.userId).then( result => {
        this.sales = result.data.data
        this.users = [{
          id: this.$store.state.userId,
          username: this.$store.state.username,
          role: this.$store.state.userRole,
          position: this.$store.state.position
        }]
        console.log(this.users)
      }).catch( err => {
        console.log(err)
         this.$toast.error("Algo pasó. Vuelve a Cargar la Página");

      }).finally( () => {
        this.isLoading = false;
      })
    }

  }
};
</script>

<style src="../../main.css"></style>

