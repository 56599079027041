<template>
  <div>
    <!-- <CreateButton :create="create" @toggleCreates="create = $event" /> -->
    <b-container>
      <b-row >
        <b-col md="10" lg="12" class="mx-auto">
          <!-- <SelectFor v-model="form.companyId" :input-label="inputLabel" :options="options"/> -->
          <h1>Reporte Detallado </h1>

          <div v-if="!isLoading">
          <md-card class="text-left" id="user-box">
            <md-card-area md-inset  v-custom-click-outside="hide">
              <a @click="displayUsers()">
                <md-card-header id="detailed-report-user" >
                    <md-avatar v-if="selectedUserObj.username != null">
                      <h2>{{ selectedUserObj.username[0].toUpperCase() }}</h2>
                    </md-avatar>
                  <div class="md-title" v-if="selectedUserObj.username != null">{{selectedUserObj.username}}</div>
                  <div class="md-subhead" v-if="selectedUserObj.position != null">{{selectedUserObj.position}}</div>    
                </md-card-header>                
              </a>
              <md-card-header  v-if="showUsers" class="second-header"> 
                <div id="list-of-users">
                  <div v-for="item in users" :key="item.id" class="user-item" @click="hideUsers(item.id)" :class="{active: item.id == selectedUser}">
                    <md-avatar>
                      <h2>{{ item.username[0].toUpperCase() }}</h2>
                </md-avatar>
                     <div class="md-title">{{item.username}}</div>
                      <div class="md-subhead">{{item.position}}</div>   
                  </div>
                </div> 
              </md-card-header>
              
                
             
            </md-card-area>

            <md-card-content id="date-selector" v-if="!isUsingReportId">
                <md-datepicker v-model="selectedDate" :md-disabled-dates="disabledDates">
                  <label>Seleccione una Fecha</label>
                </md-datepicker>
              <h3 class="md-subheading" v-if="selectedDate && filtered.length >= 1">Tiempo reportado esta semana: <strong>{{totalTime}} hrs</strong></h3>
            </md-card-content>
          </md-card>

          <md-table v-model="filtered[0].details" v-if="selectedDate && filtered.length >= 1" >
            <md-table-toolbar>
              <div class="md-title text-left">Semana: {{toString(filtered[0].startDate) +'-'+ toString(filtered[0].endDate)}} </div>
              <md-button class="md-primary" @click="showDialog = true">Agregar Reporte</md-button>              
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }" @click="showThisInfo(item)">
              <md-table-cell md-label="Ciente">{{
                clients[clients.findIndex((e) => e.id == item.clientId)].name
              }}</md-table-cell>
              <md-table-cell md-label="Proyecto">{{
                projects[projects.findIndex((e) => e.id == item.projectId)].name
              }}</md-table-cell>
              <md-table-cell md-label="Actividad">{{
                tasks[tasks.findIndex((e) => e.id == item.taskId)].name
              }}</md-table-cell>
              <md-table-cell :md-label="'Lunes ' + daysLabels.mon">{{ item.mon }}</md-table-cell>
              <md-table-cell :md-label="'Martes ' + daysLabels.tue">{{ item.tue }}</md-table-cell>
              <md-table-cell :md-label="'Miercoles ' + daysLabels.wed">{{
                item.wed
              }}</md-table-cell>
              <md-table-cell :md-label="'Jueves ' + daysLabels.thu">{{ item.thu }}</md-table-cell>
              <md-table-cell :md-label="'Viernes ' + daysLabels.fri">{{
                item.fri
              }}</md-table-cell>
              <md-table-cell :md-label="'Sabado ' + daysLabels.sat">{{ item.sat }}</md-table-cell>
              <md-table-cell md-label="Total">{{
                item.total
              }}</md-table-cell>
            </md-table-row>
          </md-table>
          <div v-if="selectedDate && filtered.length == 0 ">
            <md-empty-state
              md-icon="devices_other"
              md-label="No tienes reportes para esa semana"
              md-description="Si deseas crear uno nuevo con la fecha de inicio indicada has click en el siguiente botón">
              <md-button class="md-primary md-raised" @click="createNewReport()">Crea uno nuevo</md-button>
            </md-empty-state>
          </div>
          </div>  
          <div v-else class="text-center"><b-spinner></b-spinner></div>
        </b-col>
      </b-row>
    </b-container>
    <md-dialog v-if="showDialog" :md-active.sync="showDialog">
      <md-dialog-title> Llene los siguientes campos </md-dialog-title>
      <md-dialog-content>
        <md-field>
          <md-select v-model="dialogItem.clientId" placeholder="Cliente">
            <md-option
              v-for="(item, idx) in clients"
              :key="'client-id-act-' + idx"
              :value="item.id"
              >{{ item.name }}</md-option
            >
          </md-select>
        </md-field>
        <md-field v-if="dialogItem.clientId">
          <md-select v-model="dialogItem.projectId" placeholder="Proyecto">
            <md-option
              v-for="(item, idx) in projects.filter(el => el.clientId == dialogItem.clientId)"
              :key="'project-id-act-' + idx"
              :value="item.id"
              >{{ item.name }}</md-option
            >
          </md-select>
        </md-field>
        <md-field v-if="dialogItem.projectId">
          <md-select v-model="dialogItem.taskId" placeholder="Tarea">
            <md-option
              v-for="(item, idx) in tasks.filter(el => el.projectId == dialogItem.projectId)"
              :key="'task-id-act-' + idx"
              :value="item.id"
              >{{ item.name }}</md-option
            >
          </md-select>
        </md-field>
        <div v-if="dialogItem.taskId">
         <md-field>
            <label>Lunes</label>
            <md-input v-model="dialogItem.mon" type="number"></md-input>
          </md-field>
          <md-field>
            <label>Martes</label>
            <md-input v-model="dialogItem.tue" type="number"></md-input>
          </md-field>
          <md-field>
            <label>Miercoles</label>
            <md-input v-model="dialogItem.wed" type="number"></md-input>
          </md-field>
          <md-field>
            <label>Jueves</label>
            <md-input v-model="dialogItem.thu" type="number"></md-input>
          </md-field>
          <md-field>
            <label>Viernes</label>
            <md-input v-model="dialogItem.fri" type="number"></md-input>
          </md-field>
          <md-field>
            <label>Sábado</label>
            <md-input v-model="dialogItem.sat" type="number"></md-input>
          </md-field>
          </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button v-if="isUpdating" class="md-primary" @click="updateReportDetails()"
          >Actualizar</md-button
        >
        <md-button v-if="dialogItem.taskId && !isUpdating" class="md-primary" @click="updateReportDetails()"
          >Crear</md-button
        >
        <md-button class="md-accent" @click="showDialog = false"
          >Cerrar</md-button
        >

      </md-dialog-actions>
    </md-dialog>

  </div>
</template>

<script>
// import SelectFor from "@/components/utils/SelectFor";
// import parse from "date-fns/parse";
import format from "date-fns/format";
// import CreateButton from "@/components/utils/CreateButton";

import User from "@/services/User";
import Report from "@/services/time-report/Report";
import Project from "@/services/time-report/Project";
import Task from "@/services/time-report/Task";
import Client from "@/services/time-report/Client";

export default {
  data() {
    return {
      selectedDate: null,
      disabledDates: date => {
        const day = date.getDay()
        return day === 0 || day === 2 || day === 3 || day === 4 || day === 5 || day === 6
      },
      isUsingReportId : false,
      selectedUser: null, 
      create: false,
      showDialog: false,
      isUpdating: false,
      reportItem: {
        userId: null,
        startDate: null,
        endDate: null,
        details: []
      },
      dialogItem: {
        clientId: "",
        projectId: "",
        taskId: "",
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        total: 0
      },
      string: null,
      isLoading: false,
      inputLabel: "Company",
      showUsers: false,
      status: [
        {
          value: "sin_comenzar",
          name: "Sin Comenzar",
        },
        {
          value: "en_progreso",
          name: "En Progreso",
        },
        {
          value: "devuelta",
          name: "Devuelta",
        },
        {
          value: "terminada",
          name: "Terminada",
        },
      ],
      reports: [],
      users: [],
      clients: [],
      projects: [],
      tasks: [],
    };
  },
  components: {
    // SelectFor
    // CreateButton,
  },
  computed: {
    dateFormat() {
      return this.$material.locale.dateFormat || "dd/MM/yyyy";
    },
    daysLabels() {
      return{
        mon: (new Date(this.selectedDate)).getDate(),
        tue: (new Date((new Date(this.selectedDate)).getTime() + 1 * 86400000)).getDate() ,
        wed: (new Date((new Date(this.selectedDate)).getTime() + 2 * 86400000)).getDate(),
        thu: (new Date((new Date(this.selectedDate)).getTime() + 3 * 86400000)).getDate(),
        fri: (new Date((new Date(this.selectedDate)).getTime() + 4 * 86400000)).getDate(),
        sat: (new Date((new Date(this.selectedDate)).getTime() + 5 * 86400000)).getDate()
      }
      },
    filtered(){
      console.log(new Date(this.selectedDate))
      console.log(this.reports[0].startDate)
      return this.reports.filter(el => el.userId == this.selectedUser && this.toString(el.startDate) == this.toString(this.selectedDate))
    },
    totalTime(){
      let total = 0
      this.filtered[0].details.forEach(el => {
        // console.log(el)
        total += Number(el.total) 
      });
      return total
    },
    selectedUserObj(){
      return {
        username: this.users.length > 0 ? this.users[this.users.findIndex(el => el.id == this.selectedUser)].username : null,
        position: this.users.length > 0 ? this.users[this.users.findIndex(el => el.id == this.selectedUser)].position : null
      }
    }
  },
  methods: {
    // toDate(currDate) {
    //   return parse(currDate, this.dateFormat, new Date());
    // },
    toString(currDate) {
      return format(new Date(currDate), this.dateFormat);
    },
    toDay(currDate) {
      return format(new Date(currDate), "dd");
    },
    displayUsers(){
      if(!this.isUsingReportId){
      this.showUsers = true
      }
    },
    hideUsers(id){
      this.selectedUser = id
      this.showUsers = false
    },
    hide: function(){
      this.showUsers = false
    },
    updateReportDetails(){
      // console.log(this.filtered[0].id)
      this.isLoading = true
      this.dialogItem.total = Number(this.dialogItem.mon) + 
                              Number(this.dialogItem.tue) + 
                              Number(this.dialogItem.wed) + 
                              Number(this.dialogItem.thu) + 
                              Number(this.dialogItem.fri) +
                              Number(this.dialogItem.sat)
      if(!this.isUpdating){
        this.filtered[0].details.push(this.dialogItem)
      }
      Report.updateOneById(this.filtered[0].id,this.filtered[0])
      .then((result) => {
        console.log(result)
        this.$toast.success("Reporte agregado");
      }).catch((err) => {
        this.$toast.error("Algo pasó. Vuelve a Cargar la Página");
        console.log(err)
      }).finally(() => {
        this.dialogItem = {
        clientId: "",
        projectId: "",
        taskId: "",
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        total: 0,
      }
      this.isLoading = false
      this.isUpdating = false
      this.showDialog = false
      });
      
    },
    createNewReport(){
      this.isLoading = true
      this.reportItem.userId = this.$store.state.userId
      this.reportItem.startDate = this.selectedDate
      this.reportItem.endDate = new Date((new Date(this.reportItem.startDate)).getTime() + 5 * 86400000)
      Report.createOne(this.reportItem)
      .then( result => {
        this.reports.push(result.data.data)
        this.$toast.success("Creado Correctamente");
      })
      .catch(err => {
        console.log(err)
        this.$toast.error("Algo pasó");

      })
      .finally(() => {
        this.isLoading = false
        this.reportItem = {
        userId: null,
        startDate: null,
        endDate: null,
        details: []
      }
      })
    },
    showThisInfo(item) {
      this.isUpdating = true;
      this.showDialog = true;
      console.log(item)
      this.dialogItem = item;
    },
    // toggleCreate() {
    //   this.create = !this.create;
    // },
  },
  beforeMount(){
    // if(this.$route.params.reportId){
    //   // SERVICE TO FETCH REPORT BY ID
    //   Report.getOneById(this.$route.params.reportId)
    //   .then(result => {
    //     this.reports = result.data.data;
    //     this.selectedDate = result.data.data[0].startDate;
    //     this.selectedUser = result.data.data[0].userId;
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
    // }else{
    //   Report.getAll().then(result => {
    //     this.reports = result.data.data
    //   }).catch(error => {
    //     console.log(error)
    //   })
    //   this.selectedUser = this.$store.state.userId
    // }
  },
  async mounted(){
    this.isLoading = true
    if(this.$route.params.reportId){
      // SERVICE TO FETCH REPORT BY ID
      this.isUsingReportId = true
      try{
        this.reports = (await Report.getOneById(this.$route.params.reportId)).data.data
        this.selectedDate = new Date(this.reports[0].startDate);
        this.selectedUser = this.reports[0].userId;
      }
      catch(error) {
        console.log(error)
      }
    }else{
      try{
         this.reports = (await Report.getAll()).data.data
      }catch(error) {
        console.log(error)
      }
      this.selectedUser = this.$store.state.userId
    }
    // Get all clients
    // this.selectedUser = this.$store.state.userId
    try{
      this.clients = (await Client.getAll()).data.data
    }catch(error) {
      console.log(error)
    }
    // Get all Projects
    try{
      this.projects = (await Project.getAll()).data.data
    }catch(error) {
      console.log(error)
    }
    // Get all Tasks
    try{
      this.tasks = (await Task.getAll()).data.data
    }catch(error) {
      console.log(error)
    }
    if(this.$store.state.userRole == 'SUPERCOM'){
      try{
        this.users = (await User.getAll()).data.users
      }catch(err) {
        console.log(err)
        this.$toast.error("Algo pasó. Vuelve a Cargar la Página");
      }
    }else{
      this.users = [{
          id: this.$store.state.userId,
          username: this.$store.state.username,
          role: this.$store.state.userRole,
          position: this.$store.state.position
        }]
    }    
    this.isLoading = false
    // console.log(this.clients)
    // console.log(this.projects)
    // console.log(this.reports)
    // console.log(this.tasks)


  }
};
</script>

<style src="../../main.css"></style>

