<template>
  <div class="cfs-task">
    <md-field :class="getValidationClass('name')">
      <label>Nombre</label>
      <md-input v-model="taskItem.name"></md-input>
      <span class="md-error" v-if="!$v.taskItem.name.required"
        >El nombre es requerido</span
      >
    </md-field>
    <md-field :class="getValidationClass('status')">
      <label for="task-status">Selecione el estado</label>
      <md-select id="task-status" v-model="taskItem.status">
        <md-option
          v-for="(item, idx) in options"
          :key="idx"
          :value="item.value"
          >{{ item.name }}</md-option
        >
      </md-select>
      <span class="md-error" v-if="!$v.taskItem.status.required"
        >El estado es requerido</span
      >
    </md-field>
    <md-button class="md-raised task-button" @click.stop="sendTaskItem()">Crear Tarea</md-button>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "CreateTask",
  mixins: [validationMixin],
  props: {
    projectId: String,
    options: Array,
  },
  data() {
    return {
      taskItem: {
          projectId: this.projectId,
          name: "",
          status: "",
      },
    };
  },
  validations: {
    taskItem: {
      name: {
        required,
      },
      status: {
        required,
      },
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.taskItem[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    sendTaskItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("newTaskEvent",this.taskItem);
      }
    },
  },
};
</script>

<style>
.cfs-task {
  padding: 4px 32px 4px 32px;
}
.task-button{
    border-radius: 6px !important;
    margin: 6px 0 !important;
    width: 100%;
    color: #fff !important;
    background: var(--first) !important;
}
</style>
