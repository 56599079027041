<template>
  <div class="cfs-project">
    <md-field :class="getValidationClass('name')">
      <label>Nombre</label>
      <md-input
        v-model="projectItem.name"
      ></md-input>
      <span class="md-error" v-if="!$v.projectItem.name.required"
        >El nombre es requerido</span
      >
    </md-field>
    <md-field :class="getValidationClass('status')">
      <label for="project-status">Selecione el estado</label>
      <md-select
        id="project-status"
        v-model="projectItem.status"
      >
        <md-option
          v-for="(item, idx) in options"
          :key="idx"
          :value="item.value"
          >{{ item.name }}</md-option
        >
      </md-select>
      <span class="md-error" v-if="!$v.projectItem.status.required"
        >El estado es requerido</span
      >
    </md-field>
    <md-button class="md-raised project-button" @click.stop="sendProjectItem()"
      >Crear Proyecto</md-button
    >
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "CreateProject",
  mixins: [validationMixin],
  props: {
    clientId: String,
    options: Array,
  },
  data() {
    return {
      projectItem: {
        clientId: this.clientId,
        name: null,
        status: null,
      },
    };
  },
  validations: {
    projectItem: {
      name: {
        required,
      },
      status: {
        required,
      },
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.projectItem[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    sendProjectItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$emit("newProjectEvent", this.projectItem);
      }
    },
  },
};
</script>

<style>
.cfs-project {
  padding: 4px 32px 4px 32px;
}
.project-button {
  border-radius: 6px !important;
  margin: 6px 0 !important;
  width: 100%;
  color: #fff !important;
  background: var(--first) !important;
}
</style>
