import Api from "@/services/Api";

export default {
  getAll() {
    return Api().get("clients");
  },
  getOneById(id) {
    return Api().get("clients/" + id);
  },
  deleteById(id) {
    return Api().put("clients/delete/" + id);
  },
  updateOneById(id,client) {
    return Api().put("clients/update/"+id,client);
  },
  createOne(form) {
    return Api().post("clients", form);
  },
};
