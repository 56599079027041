import Api from '@/services/Api'

export default {
    createOne(form){
        return Api().post('activities',form)
    },
    getAll(){
        return Api().get('activities')
    },
    updateOne(id, form){
        return Api().put('activities/update/'+id,form)
    },
    getAllByCompany(id){
        return Api().get('activities/company/'+id)
    },
    getAllByService(id){
        return Api().get('activities/service/'+id)
    },
    changeStatus(activityId, status) {
        return Api().put('activities/status/'+activityId, {status: status})
      }
}