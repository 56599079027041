<template>
  <div class="deliver-list">
         <CreateButton :create="create" @toggleCreates="create = $event"/>

    <b-container>
      <b-row v-if="!create">
        <b-col md="8" class="mx-auto">
          <h1>Entregables</h1>
          <div v-if="!isLoading">
          <md-table
            v-model="items"
            md-sort="createdAt"
            md-sort-order="asc"
            v-if="items.length > 0"
            class="customer"
          >
            <md-table-toolbar>
              <h1 class="md-title text-left">Complement Services</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Fecha" md-sort-by="createdAt">{{
                toString(item.createdAt)
              }}</md-table-cell>
              <md-table-cell md-label="Compañia" md-sort-by="companyId"  v-if="$store.state.canEdit">{{
                companies[companies.findIndex((e) => e.id == item.companyId)]
                  .name
              }}</md-table-cell>
              <md-table-cell md-label="Servicio" md-sort-by="name">{{
                services[services.findIndex((e) => e.id == item.serviceId)].name
              }}</md-table-cell>
              <md-table-cell md-label="Detalle" md-sort-by="details">{{
                item.details
              }}</md-table-cell>
              <md-table-cell md-label="Archivo" md-sort-by="document"
                ><md-button @click="downloadThis(item.id)"
                  ><font-awesome-icon :icon="['fas', 'download']"/></md-button
              ></md-table-cell>
              <md-table-cell md-label="Fecha de lectura" md-sort-by="readDate">{{
                toString(item.readDate)
              }}</md-table-cell>
            </md-table-row>
          </md-table>
          <div v-else>
            <md-empty-state
              md-icon="devices_other"
              md-label="Crea tu primer entregable"
              md-description="Agrega un documento junto con el detalle">
              <md-button class="md-primary md-raised" @click="create = true">Crear</md-button>
            </md-empty-state>
          </div>
          </div>
          <div class="text-center" v-else><b-spinner></b-spinner></div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="8" sm="10" lg="6" class="mx-auto">
          <h1>Crear Entregable</h1>
          <md-card>
            <md-card-content>
              <form @submit.prevent="submitForm" enctype="multipart/form-data">
                <b-form-group>
                  <md-field  v-if="$store.state.canEdit">
                    <md-select
                      v-model="deliver.companyId"
                      placeholder="Seleccione una compañia"
                      @input="clearService()"
                    >
                      <md-option
                        v-for="(item, idx) in companies"
                        :key="'company-id-act-' + idx"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                  <md-field v-if="deliver.companyId">
                    <md-select
                      v-model="deliver.serviceId"
                      placeholder="Seleccione un servicio"
                    >
                      <md-option
                        v-for="(item, idx) in services.filter(
                          (e) => e.companyId == deliver.companyId
                        )"
                        :key="'service-id-act-' + idx"
                        :value="item.id"
                        >{{ item.name }}</md-option
                      >
                    </md-select>
                  </md-field>
                  <md-field>
                    <label>Detalle</label>
                    <md-input
                      v-model="deliver.details"
                      md-counter="100"
                    ></md-input>
                  </md-field>
                  <md-field>
                    <label>Documento</label>
                    <md-file v-model="deliver.deliverDocument.name"  @md-change="selectFile($event)"/>
                  </md-field>
                  <!-- <md-field>
                    <label>Comentarios</label>
                    <md-textarea
                      v-model="document.comments"
                      md-autogrow
                    ></md-textarea>
                  </md-field> -->

                  <md-button type="submit" class="create-button">Crear</md-button>
                </b-form-group>
              </form>
            </md-card-content>
          </md-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- <DynamicTable :data="items" :head-labels="titles"/> -->
  </div>
</template>

<script>
// import DynamicTable from '@/components/utils/DynamicTable'
import format from "date-fns/format";
import CreateButton from "@/components/utils/CreateButton";
import Deliver from "@/services/customer/Deliver";
import Service from "@/services/customer/Service";
import Company from "@/services/Company";
export default {
  data() {
    return {
      create: false,
      isLoading: false,
      deliver: {
        createdAt: "",
        details: "",
        serviceId: "",
        companyId: "",
        deliverDocument: { name: "" },
        readDate: null,
      },
      titles: ["servicio", "oferta mercantil", "acta de inicio", "propuesta"],
      items: [],
      services: [],
      companies: [],
    };
  },
  components: {
    CreateButton
  },
  methods: {
    downloadThis(id) {
        const tempIdx = this.items.findIndex(e => e.id === id)
        if(!this.items[tempIdx].readDate && this.$store.state.canEdit){
          let dateFormat = this.$material.locale.dateFormat || 'dd/MM/yyyy'
          let now = new Date()
          this.items[tempIdx].readDate =  format(now, dateFormat) 
          Deliver.updateOne(id,{readDate: now}).then(() => {
            this.$toast.success("Fecha de lectura actualizada")
          })
        }
        // Continue
        Deliver.downloadFile(id).then((response) => {
        const ext = this.items[this.items.findIndex(e => e.id === id)].deliverDocument.split('.').pop()
        const url = URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id + "." + ext);
        document.body.appendChild(link);
        link.click();
      });
    },
    selectFile(evt){
      this.deliver.deliverDocument = evt[0];
    },
    submitForm() {
      this.isLoading = true
      let formData = new FormData()
      // formData.append('createdAt', new Date())
      if(this.deliver.serviceId && this.deliver.details && this.deliver.deliverDocument.name){
      if(this.$store.state.canEdit){
        formData.append('companyId',this.deliver.companyId)
      }else{
        formData.append('companyId',this.$store.state.companyId)
      }
      formData.append('details',this.deliver.details)
      formData.append('serviceId',this.deliver.serviceId)
      formData.append('file',this.deliver.deliverDocument)
      Deliver.createOne(formData)
        .then((result) => {
            this.create = false
            this.items.push(result.data.data)
            this.$toast.success("Creado Correctamente")
        }).catch((err) => {
            this.$toast.error("Algo pasó")
            console.log(err)
        }).finally(() => {
          this.isLoading = false
          this.resetForm();
        })
        }else{
      this.$toast.error("Diligencia los campos")
      this.isLoading = false;
    }
    },
    resetForm() {
      this.deliver = {
        createdAt: "",
        details: "",
        serviceId: "",
        companyId: "",
        deliverDocument: { name: "" },
        readDate: null,
      };
    },
    clearService() {4
      this.deliver.serviceId = "";
    },
    toString(currDate) {
      if(currDate){
      return format(new Date(currDate),this.$material.locale.dateFormat)
      }else{
        return ""
      }
    },
  },
  async mounted(){
    this.isLoading = true
    try{
      this.items = (await Deliver.getAll()).data.data
      this.companies = (await Company.getAll()).data.companies
      this.services = (await Service.getAll()).data.data
      if(!this.$store.state.canEdit){
        this.services = this.services.filter(el => el.companyId == this.$store.state.companyId)
        this.items = this.items.filter(el => el.companyId == this.$store.state.companyId)
      }
    }catch(error){
      console.log(error)
    }
    this.isLoading = false
  }
};
</script>

<style></style>
